import { CheckOutlined } from "@ant-design/icons";

const CustomCheckBox = ({ children = null, size = 16, label = "" as any, className = "", customWidth = "", overlaystyle = {} }) => {
    return (
      <div className={`vx-checkbox-con ${className}`}>
        {children}
        <span
          className={`vx-checkbox vx-checkbox-md ${customWidth}`}
          style={overlaystyle}
        >
          <span className="vx-checkbox--check">
            <CheckOutlined rev="" className="vx-icon" />
          </span>
        </span>
        {label}
      </div>
    );
};
export default CustomCheckBox;