import { addData, requestSignupOtp, setData, userRegister } from '@/actions/auth';
import APIServices from '@/apiUtils/APIServices';
import { country_codes } from '@/helpers/countries';
import { authentication } from '@/helpers/Firebase/firebase-config';
import { Modal, Spin, message, notification } from 'antd';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';
import { useRouter } from 'next/router';
import { createContext, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MultipleLoginContent } from './utils';
import PhoneForm from './PhoneForm';
import OtpForm from './OtpForm';
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import Image from 'next/image';

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}
const OnBoarding = ({
  is_instructor,
  isLoginPage = false,
  isSignUpPage = false,
  isModal = false,
}) => {
  const dispatch = useDispatch();
  const [login, setLogin] = useState(true);
  const {
    fromLogoutPage,
    userData,
    userDataRegister,
    auth: { user, address, signUpStatus, getSignupOtpStatus, verifySignupOtpStatus },
  } = useSelector((state: any) => ({
    fromLogoutPage: state.auth.fromLogOut,
    userData: state.auth.creds,
    userDataRegister: state.auth.register,
    auth: state.auth,
  }));

  const router = useRouter();
  const [expandOtpForm, setExpandOtpForm] = useState(false);
  const [OTP, setOTP] = useState('');
  const [disableResend, setDisableResend] = useState(false);
  const [phoneFormVisible, setphoneFormVisible] = useState(false);
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    email: '',
    country_code: '',
    phone_number: '',
    password: '',
    referer: '',
  });
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [formData, setFormData] = useState(null);
  const [openMultiLoginAlert, setOpenMultiLoginAlert] = useState<boolean>(false);
  //for getting partner code
  const [partnerCode, setPartnerCode] = useState('');

  const [isNewUserNepali, setIsNewUserNepali] = useState<boolean>(false);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
      authentication
    );
    clearState();
    if (isLoginPage || fromLogoutPage) setLogin(true);
    if (isSignUpPage) setLogin(false);
    dispatch(addData({ key: 'fromLogOut', value: false }));
    return () => setOpenMultiLoginAlert(false);
  }, []);

  useEffect(() => {
    if (getSignupOtpStatus === 'success') {
      setExpandOtpForm(true);
      setphoneFormVisible(false);
      dispatch(
        addData({
          key: 'getSignupOtpStatus',
          value: '',
        })
      );
    }
  }, [getSignupOtpStatus === 'success']);

  useEffect(() => {
    if (verifySignupOtpStatus === 'success') {
      notification.success({ message: 'Verified successfully' });
      isGoogleLogin
        ? handleSubmitGoogleLogin('exchange/google-oauth2/', formData)
        : processSignup();
    } else if (verifySignupOtpStatus === 'failure') {
      notification.error({ message: 'Verification Failed' });
    }
    dispatch(addData({ key: 'verifySignupOtpStatus', value: '' }));
  }, [verifySignupOtpStatus]);

  useEffect(() => {
    router?.query?.referer ? setLogin(false) : '';
  }, [router]);

  useEffect(() => {
    if (userData?.message?.is_device_verified === 'False') {
      window.location.pathname = '/verify-email';
    }
    if (!userData?.message || typeof userData?.message !== 'object') {
      setOpenMultiLoginAlert(false);
    }
    else if (typeof userData?.message === 'object' &&
      Object.keys(userData?.message).length > 0 &&
      !userData?.message?.hasOwnProperty('is_device_verified') && userData?.message?.title.includes("Multiple login")) {
      setOpenMultiLoginAlert(true);
    }
  }, [userData?.message]);

  //getting partner code from localStorage
  const partner = JSON.parse(window.localStorage.getItem('partner_code'));

  useEffect(() => {
    if (partner && partner !== 'undefined') {
      setPartnerCode(partner);
    }
  }, [partner]);

  useEffect(() => {
    if (userDataRegister?.error) {
      setOTP('');
      setExpandOtpForm(false);
    }
  }, [userDataRegister?.error]);

  useEffect(() => {
    if (userData?.status) {
      // notification.success({ message: 'User Logged in Successfully!' });
      dispatch(
        setData({
          name: 'creds',
          key: 'status',
          value: false,
        })
      );
    }
  }, [userData?.status]);

  useEffect(() => {
    if (address?.data) {
      handleDefaultValue(
        'country',
        address?.data?.country ? address?.data?.country : undefined
      );
      handleDefaultValue(
        'city',
        address?.data?.city ? address?.data?.city : undefined
      );
      handleDefaultValue(
        'country_code',
        address?.data?.countryCode
          ? country_codes?.find((each) => each?.code == address?.data?.countryCode)
              ?.mobileCode
          : '+977'
      );
    }
  }, [address?.data]);

  const clearState = () => {
    // setResendClick(false);
    setFormData({});
    setErrors({
      first_name: '',
      last_name: '',
      email: '',
      country_code: '',
      phone_number: '',
      password: '',
      referer: '',
    });
    dispatch(addData({ key: 'user', value: { country_code: '+977' } }));
    dispatch(addData({ key: 'register', value: {} }));
    dispatch(setData({ name: 'creds', key: 'message', value: '' }));
    dispatch(setData({ name: 'creds', key: 'status', value: false }));
  };

  const handleSelectFilter = (name, val) => {
    dispatch(setData({ name: 'user', key: name, value: val }));
  };

  const handleFilter = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      user_type: is_instructor ? 'instructor' : 'client',
    });
    dispatch(setData({ name: 'user', key: name, value: value }));
    dispatch(
      setData({
        name: 'user',
        key: 'user_type',
        value: is_instructor ? 'instructor' : 'client',
      })
    );
    setErrors({ ...errors, [name]: '' });
  };

  const handleDefaultValue = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
      user_type: is_instructor ? 'instructor' : 'client',
    });
    dispatch(
      setData({
        name: 'user',
        key: name,
        value: value,
      })
    );
    dispatch(
      setData({
        name: 'user',
        key: 'user_type',
        value: is_instructor ? 'instructor' : 'client',
      })
    );
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const phoneValidation = (e) => {
    e.preventDefault();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(
      authentication,
      user?.country_code + user?.phone_number,
      appVerifier
    )
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        setDisableResend(false);
        if (error?.code == 'auth/too-many-requests') {
          message.error('Too many requests. Please try again later.');
        } else if (error?.code == 'auth/quota-exceeded') {
          message.error('Quota Exceeded. Please try again later');
        } else {
          message.error(error?.message || 'Failed');
        }
        setExpandOtpForm(false);
        isGoogleLogin ? setphoneFormVisible(true) : '';
        window.recaptchaVerifier.recaptcha.reset();
      });
  };

  const submitPhoneForm = (e) => {
    e.preventDefault();
    if (user?.phone_number?.length >= 10) {
      if (user?.country_code === '+977') {
        setIsNewUserNepali(true);
        dispatch(requestSignupOtp({ phone_number: user?.phone_number }));
      } else {
        setphoneFormVisible(false);
        setExpandOtpForm(true);
        phoneValidation(e);
      }
      // handleSubmitGoogleLogin('exchange/google-oauth2/', formData)
    }
  };

  const handleLogin = (to) => {
    if (isModal) {
      setLogin(!login);
    } else {
      router.push({ pathname: `/${to}`, query: '' });
    }
    clearState();
  };

  const googleResponse = async (response) => {
    setIsGoogleLogin(true);
    let values;
    setFormData({
      ...formData,
      access_token: response?.accessToken,
      country_code: user?.country_code,
      phone_number: user?.phone_number,
      country: user?.country,
      city: user?.city,
    });
    values = {
      access_token: response?.accessToken,
      country_code: user?.country_code,
      phone_number: user?.phone_number,
      country: user?.country,
      city: user?.city,
    };
    if (router?.query?.referer) {
      setFormData({
        ...formData,
        referer: router?.query?.referer,
      });
      values = {
        ...values,
        referer: router?.query?.referer,
      };
    }
    if (is_instructor) {
      setFormData({
        ...formData,
        user_type: 'instructor',
      });
      values = {
        ...values,
        user_type: 'instructor',
      };
    }
    const { data, success } = await new APIServices('phone-Verify-email/').post({
      email: response?.profileObj?.email,
    });
    if (success) {
      if (data?.is_phone_number_verified) {
        handleSubmitGoogleLogin('exchange/google-oauth2/', values);
      } else {
        // notification.error({ message: Object.values(data?.error || {})[0] });
        setphoneFormVisible(true);
      }
    }
  };

  const CustomGoogleButton = useCallback(({ onClick }) => {
    return (
      <div className="text-center">
        <button
          type="button"
          className="btn btn-block font-weight-normal google-button p-2 border border-muted d-flex justify-content-center bg-white  border-14"
          onClick={onClick}
        >
          <div className="mr-2 mt-1">
            <Image src="/images/svgs/google.svg" height="19" width="19" />
          </div>
          <span className="gray-da-text" style={{ marginTop: '2px' }}>
            Sign in with Google
          </span>
        </button>
      </div>
    );
  }, []);

  const processSignup = () => {
    var userValues = user;
    if (router?.query?.referer) {
      userValues = {
        ...userValues,
        referer: router?.query?.referer,
      };
    }
    if (partnerCode) userValues = { ...userValues, partner_code: partnerCode };
    dispatch(userRegister(userValues));
  };

  const handleSubmitGoogleLogin = async (url, formValues) => {
    const { data, success } = await new APIServices(url).post(formValues);
    if (success) {
      window.localStorage.setItem('user', JSON.stringify(data?.user_data));
      dispatch(
        addData({
          key: 'creds',
          value: {
            isLoggedIn: true,
            access: data.access,
            user_data: data.user_data,
            status: true,
          },
        })
      );
      dispatch(
        addData({
          key: 'isLoggedIn',
          value: true,
        })
      );
      dispatch(
        setData({
          name: 'helperObj',
          key: 'loginModal',
          value: false,
        })
      );
      window.location.reload();
    } else {
      notification.warning({ message: 'Could not authenticate.' });
    }
  };

  return (
    <Spin spinning={signUpStatus === 'request' ? true : false}>
      <OnBoardingContext.Provider
        value={{
          userData,
          setOpenMultiLoginAlert,
          handleSelectFilter,
          submitPhoneForm,
          errors,
          setErrors,
          handleFilter,
          user,
          setOTP,
          OTP,
          isNewUserNepali,
          isGoogleLogin,
          processSignup,
          formData,
          phoneValidation,
          expandOtpForm,
          setExpandOtpForm,
          disableResend,
          setDisableResend,
          handleLogin,
          googleResponse,
          setIsNewUserNepali,
          CustomGoogleButton,
          handleSubmitGoogleLogin,
        }}
      >
        <div id="login-component">
          {!expandOtpForm && (
            <div className="title" style={{ marginBottom: '2px !important' }}>
              <h5 className="banner-form-title">Get Started with Learnsic!</h5>
            </div>
          )}

          <Modal
            title="Multiple Login detected"
            visible={openMultiLoginAlert}
            onCancel={() => setOpenMultiLoginAlert(false)}
            footer={false}
            centered
          >
            <MultipleLoginContent />
          </Modal>
          {phoneFormVisible ? (
            <PhoneForm />
          ) : expandOtpForm ? (
            <OtpForm />
          ) : login ? (
            <LoginForm />
          ) : (
            <SignupForm />
          )}

          <div id="recaptcha-container"></div>
        </div>
      </OnBoardingContext.Provider>
    </Spin>
  );
};

export default memo(OnBoarding);

export const OnBoardingContext = createContext<any>({});
