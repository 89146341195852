import { Spinner } from 'reactstrap';

const Loader = ({ color = "dark", size = { width: 100, height: 100 }, className = "font-size-xlg" }) => {
    return (
      <Spinner
        animation="grow"
        color={color}
        className={className}
        style={{ width: size?.width, height: size?.height }}
      />
    );
};

export default Loader;