export const GET_COURSE_WISHLIST: any = 'GET_COURSE_WISHLIST';
export const GET_COURSE_WISHLIST_FAILED: any = 'GET_COURSE_WISHLIST_FAILED';
export const GET_COURSE_WISHLIST_SUCCEEDED: any = 'GET_COURSE_WISHLIST_SUCCEEDED';

// Login Devices
export const GET_ALL_LOGIN_DEVICES_REQUEST: any = 'GET_ALL_LOGIN_DEVICES_REQUEST';
export const GET_ALL_LOGIN_DEVICES_FAILED: any = 'GET_ALL_LOGIN_DEVICES_FAILED';
export const GET_ALL_LOGIN_DEVICES_SUCCEEDED: any =
  'GET_ALL_LOGIN_DEVICES_SUCCEEDED';

export const REMOVE_LOGIN_DEVICE_REQUEST: any = 'REMOVE_LOGIN_DEVICE_REQUEST';
export const REMOVE_LOGIN_DEVICE_FAILED: any = 'REMOVE_LOGIN_DEVICE_FAILED';
export const REMOVE_LOGIN_DEVICE_SUCCEEDED: any = 'REMOVE_LOGIN_DEVICE_SUCCEEDED';

// Login Devices By Id
export const GET_LOGIN_DEVICES_BY_ID_REQUEST: any =
  'GET_LOGIN_DEVICES_BY_ID_REQUEST';
export const GET_LOGIN_DEVICES_BY_ID_FAILED: any = 'GET_LOGIN_DEVICES_BY_ID_FAILED';
export const GET_LOGIN_DEVICES_BY_ID_SUCCEEDED: any =
  'GET_LOGIN_DEVICES_BY_ID_SUCCEEDED';

// Refer
export const GET_REFER_DETAIL: any = 'GET_REFER_DETAIL';
export const GET_REFER_DETAIL_FAILED: any = 'GET_REFER_DETAIL_FAILED';
export const GET_REFER_DETAIL_SUCCEEDED: any = 'GET_REFER_DETAIL_SUCCEEDED';

export const GET_REFER_LIST: any = 'GET_REFER_LIST';
export const GET_REFER_LIST_FAILED: any = 'GET_REFER_LIST_FAILED';
export const GET_REFER_LIST_SUCCEEDED: any = 'GET_REFER_LIST_SUCCEEDED';

export const REFER_PAYOUT_REQUEST: any = 'REFER_PAYOUT_REQUEST';
export const REFER_PAYOUT_REQUEST_SUCCEEDED: any = 'REFER_PAYOUT_REQUEST_SUCCEEDED';
export const REFER_PAYOUT_REQUEST_FAILED: any = 'REFER_PAYOUT_REQUEST_FAILED';

export const REFER_PAYMENT_REQUEST: any = 'REFER_PAYMENT_REQUEST';
export const REFER_PAYMENT_REQUEST_SUCCEEDED: any =
  'REFER_PAYMENT_REQUEST_SUCCEEDED';
export const REFER_PAYMENT_REQUEST_FAILED: any = 'REFER_PAYMENT_REQUEST_FAILED';

// LearnSIC USERS
export const GET_USERS: any = 'GET_USERS';
export const GET_USERS_SUCCEEDED: any = 'GET_USERS_SUCCEEDED';
export const GET_USERS_FAILED: any = 'GET_USERS_FAILED';

export const GET_USER_DETAIL: any = 'GET_USER_DETAIL';
export const GET_USER_DETAIL_SUCCEEDED: any = 'GET_USER_DETAIL_SUCCEEDED';
export const GET_USER_DETAIL_FAILED: any = 'GET_USER_DETAIL_FAILED';
export const CLEAR_USER_STATUS: any = 'CLEAR_USER_STATUS';

export const ADD_USER: any = 'ADD_USER';
export const ADD_USER_SUCCEEDED: any = 'ADD_USER_SUCCEEDED';
export const ADD_USER_FAILED: any = 'ADD_USER_FAILED';

export const EDIT_USER: any = 'EDIT_USER';
export const EDIT_USER_SUCCEEDED: any = 'EDIT_USER_SUCCEEDED';
export const EDIT_USER_FAILED: any = 'EDIT_USER_FAILED';

export const DELETE_USER: any = 'DELETE_USER';
export const DELETE_USER_SUCCEEDED: any = 'DELETE_USER_SUCCEEDED';
export const DELETE_USER_FAILED: any = 'DELETE_USER_FAILED';

export const SEARCH_USER: any = 'SEARCH_USER';
export const SEARCH_USER_SUCCEEDED: any = 'SEARCH_USER_SUCCEEDED';
export const SEARCH_USER_FAILED: any = 'SEARCH_USER_FAILED';

export const SET_USER_DATA: any = 'SET_USER_DATA';
export const SET_USER_EDIT_DATA: any = 'SET_USER_EDIT_DATA';
export const SET_USER_ERROR_DATA: any = 'SET_USER_ERROR_DATA';

//Users Task
export const GET_CLIENT_TASKS_REQUEST: any = 'GET_CLIENT_TASKS_REQUEST';
export const GET_CLIENT_TASKS_SUCCEEDED: any = 'GET_CLIENT_TASKS_SUCCEEDED';
export const GET_CLIENT_TASKS_FAILED: any = 'GET_CLIENT_TASKS_FAILED';

export const ADD_CLIENT_TASKS_REQUEST: any = 'ADD_CLIENT_TASKS_REQUEST';
export const ADD_CLIENT_TASKS_SUCCEEDED: any = 'ADD_CLIENT_TASKS_SUCCEEDED';
export const ADD_CLIENT_TASKS_FAILED: any = 'ADD_CLIENT_TASKS_FAILED';

export const EDIT_CLIENT_TASKS_REQUEST: any = 'EDIT_CLIENT_TASKS_REQUEST';
export const EDIT_CLIENT_TASKS_SUCCEEDED: any = 'EDIT_CLIENT_TASKS_SUCCEEDED';
export const EDIT_CLIENT_TASKS_FAILED: any = 'EDIT_CLIENT_TASKS_FAILED';

export const DELETE_CLIENT_TASKS_REQUEST: any = 'DELETE_CLIENT_TASKS_REQUEST';
export const DELETE_CLIENT_TASKS_SUCCEEDED: any = 'DELETE_CLIENT_TASKS_SUCCEEDED';
export const DELETE_CLIENT_TASKS_FAILED: any = 'DELETE_CLIENT_TASKS_FAILED';

//Users Note
export const GET_CLIENT_NOTES_REQUEST: any = 'GET_CLIENT_NOTES_REQUEST';
export const GET_CLIENT_NOTES_SUCCEEDED: any = 'GET_CLIENT_NOTES_SUCCEEDED';
export const GET_CLIENT_NOTES_FAILED: any = 'GET_CLIENT_NOTES_FAILED';

export const ADD_CLIENT_NOTES_REQUEST: any = 'ADD_CLIENT_NOTES_REQUEST';
export const ADD_CLIENT_NOTES_SUCCEEDED: any = 'ADD_CLIENT_NOTES_SUCCEEDED';
export const ADD_CLIENT_NOTES_FAILED: any = 'ADD_CLIENT_NOTES_FAILED';

export const EDIT_CLIENT_NOTES_REQUEST: any = 'EDIT_CLIENT_NOTES_REQUEST';
export const EDIT_CLIENT_NOTES_SUCCEEDED: any = 'EDIT_CLIENT_NOTES_SUCCEEDED';
export const EDIT_CLIENT_NOTES_FAILED: any = 'EDIT_CLIENT_NOTES_FAILED';

export const DELETE_CLIENT_NOTES_REQUEST: any = 'DELETE_CLIENT_NOTES_REQUEST';
export const DELETE_CLIENT_NOTES_SUCCEEDED: any = 'DELETE_CLIENT_NOTES_SUCCEEDED';
export const DELETE_CLIENT_NOTES_FAILED: any = 'DELETE_CLIENT_NOTES_FAILED';

//Users Payment history
export const GET_CLIENT_PAYMENT_HISTORY_REQUEST: any =
  'GET_CLIENT_PAYMENT_HISTORY_REQUEST';
export const GET_CLIENT_PAYMENT_HISTORY_SUCCEEDED: any =
  'GET_CLIENT_PAYMENT_HISTORY_SUCCEEDED';
export const GET_CLIENT_PAYMENT_HISTORY_FAILED: any =
  'GET_CLIENT_PAYMENT_HISTORY_FAILED';
// export const SET_CLIENT_TASKS: any = 'SET_CLIENT_TASKS';

//course
export const GET_COURSES: any = 'GET_COURSES';
export const GET_COURSES_SUCCEEDED: any = 'GET_COURSES_SUCCEEDED';
export const GET_COURSES_FAILED: any = 'GET_COURSES_FAILED';

export const GET_COURSES_BY_PAGE: any = 'GET_COURSES_BY_PAGE';
export const GET_COURSES_BY_PAGE_SUCCEEDED: any = 'GET_COURSES_BY_PAGE_SUCCEEDED';
export const GET_COURSES_BY_PAGE_FAILED: any = 'GET_COURSES_BY_PAGE_FAILED';

export const GET_COURSE_BY_SLUG: any = 'GET_COURSE_BY_SLUG';
export const GET_COURSE_BY_SLUG_SUCCEEDED: any = 'GET_COURSE_BY_SLUG_SUCCEEDED';
export const GET_COURSE_BY_SLUG_FAILED: any = 'GET_COURSE_BY_SLUG_FAILED';

export const ADD_COURSE_TO_WISHLIST: any = 'ADD_COURSE_TO_WISHLIST';
export const ADD_COURSE_TO_WISHLIST_SUCCEEDED: any =
  'ADD_COURSE_TO_WISHLIST_SUCCEEDED';
export const ADD_COURSE_TO_WISHLIST_FAILED: any = 'ADD_COURSE_TO_WISHLIST_FAILED';

export const ADD_COURSE: any = 'ADD_COURSE';
export const ADD_COURSE_SUCCEEDED: any = 'ADD_COURSE_SUCCEEDED';
export const ADD_COURSE_FAILED: any = 'ADD_COURSE_FAILED';

export const EDIT_COURSE: any = 'EDIT_COURSE';
export const EDIT_COURSE_SUCCEEDED: any = 'EDIT_COURSE_SUCCEEDED';
export const EDIT_COURSE_FAILED: any = 'EDIT_COURSE_FAILED';

export const DELETE_COURSE: any = 'DELETE_COURSE';
export const DELETE_COURSE_SUCCEEDED: any = 'DELETE_COURSE_SUCCEEDED';
export const DELETE_COURSE_FAILED: any = 'DELETE_COURSE_FAILED';

export const SET_COURSE_DATA: any = 'SET_COURSE_DATA';
export const SET_COURSE_EDIT_DATA: any = 'SET_COURSE_EDIT_DATA';
export const SET_COURSE_ERROR_DATA: any = 'SET_COURSE_ERROR_DATA';
export const CLEAR_COURSE_DATA: any = 'CLEAR_COURSE_DATA';
export const SET_COURSE_ENROLL_EDIT_DATA: any = 'SET_COURSE_ENROLL_EDIT_DATA';
export const SET_COURSE_QUERY_DATA: any = 'SET_COURSE_QUERY_DATA';
export const CLEAR_COURSE_QUERY: any = 'CLEAR_COURSE_QUERY';

//test
export const GET_TESTS: any = 'GET_TESTS';
export const GET_TESTS_SUCCEEDED: any = 'GET_TESTS_SUCCEEDED';
export const GET_TESTS_FAILED: any = 'GET_TESTS_FAILED';

export const ADD_TEST: any = 'ADD_TEST';
export const ADD_TEST_SUCCEEDED: any = 'ADD_TEST_SUCCEEDED';
export const ADD_TEST_FAILED: any = 'ADD_TEST_FAILED';

export const EDIT_TEST: any = 'EDIT_TEST';
export const EDIT_TEST_SUCCEEDED: any = 'EDIT_TEST_SUCCEEDED';
export const EDIT_TEST_FAILED: any = 'EDIT_TEST_FAILED';

export const DELETE_TEST: any = 'DELETE_TEST';
export const DELETE_TEST_SUCCEEDED: any = 'DELETE_TEST_SUCCEEDED';
export const DELETE_TEST_FAILED: any = 'DELETE_TEST_FAILED';

export const SET_TEST_DATA: any = 'SET_TEST_DATA';
export const SET_TEST_EDIT_DATA: any = 'SET_TEST_EDIT_DATA';
export const SET_TEST_ERROR_DATA: any = 'SET_TEST_ERROR_DATA';

//subject
export const GET_SUBJECTS: any = 'GET_SUBJECTS';
export const GET_SUBJECTS_SUCCEEDED: any = 'GET_SUBJECTS_SUCCEEDED';
export const GET_SUBJECTS_FAILED: any = 'GET_SUBJECTS_FAILED';

export const GET_SUBJECT_BY_SLUG: any = 'GET_SUBJECT_BY_SLUG';
export const GET_SUBJECT_BY_SLUG_SUCCEEDED: any = 'GET_SUBJECT_BY_SLUG_SUCCEEDED';
export const GET_SUBJECT_BY_SLUG_FAILED: any = 'GET_SUBJECT_BY_SLUG_FAILED';

export const GET_SUBJECTS_BY_PAGE: any = 'GET_SUBJECTS_BY_PAGE';
export const GET_SUBJECTS_BY_PAGE_SUCCEEDED: any = 'GET_SUBJECTS_BY_PAGE_SUCCEEDED';
export const GET_SUBJECTS_BY_PAGE_FAILED: any = 'GET_SUBJECTS_BY_PAGE_FAILED';

export const ADD_SUBJECT: any = 'ADD_SUBJECT';
export const ADD_SUBJECT_SUCCEEDED: any = 'ADD_SUBJECT_SUCCEEDED';
export const ADD_SUBJECT_FAILED: any = 'ADD_SUBJECT_FAILED';

export const EDIT_SUBJECT: any = 'EDIT_SUBJECT';
export const EDIT_SUBJECT_SUCCEEDED: any = 'EDIT_SUBJECT_SUCCEEDED';
export const EDIT_SUBJECT_FAILED: any = 'EDIT_SUBJECT_FAILED';

export const DELETE_SUBJECT: any = 'DELETE_SUBJECT';
export const DELETE_SUBJECT_SUCCEEDED: any = 'DELETE_SUBJECT_SUCCEEDED';
export const DELETE_SUBJECT_FAILED: any = 'DELETE_SUBJECT_FAILED';

export const SET_SUBJECT_DATA: any = 'SET_SUBJECT_DATA';
export const SET_SUBJECT_EDIT_DATA: any = 'SET_SUBJECT_EDIT_DATA';
export const SET_SUBJECT_ERROR_DATA: any = 'SET_SUBJECT_ERROR_DATA';

export const COPY_SUBJECT_CONTENT: any = 'COPY_SUBJECT_CONTENT';
export const COPY_SUBJECT_CONTENT_SUCCEEDED: any = 'COPY_SUBJECT_CONTENT_SUCCEEDED';
export const COPY_SUBJECT_CONTENT_FAILED: any = 'COPY_SUBJECT_CONTENT_FAILED';

//chapter
export const GET_CHAPTERS: any = 'GET_CHAPTERS';
export const GET_CHAPTERS_SUCCEEDED: any = 'GET_CHAPTERS_SUCCEEDED';
export const GET_CHAPTERS_FAILED: any = 'GET_CHAPTERS_FAILED';

export const GET_CHAPTER_BY_SLUG: any = 'GET_CHAPTER_BY_SLUG';
export const GET_CHAPTER_BY_SLUG_SUCCEEDED: any = 'GET_CHAPTER_BY_SLUG_SUCCEEDED';
export const GET_CHAPTER_BY_SLUG_FAILED: any = 'GET_CHAPTER_BY_SLUG_FAILED';

export const ADD_CHAPTER: any = 'ADD_CHAPTER';
export const ADD_CHAPTER_SUCCEEDED: any = 'ADD_CHAPTER_SUCCEEDED';
export const ADD_CHAPTER_FAILED: any = 'ADD_CHAPTER_FAILED';

export const EDIT_CHAPTER: any = 'EDIT_CHAPTER';
export const EDIT_CHAPTER_SUCCEEDED: any = 'EDIT_CHAPTER_SUCCEEDED';
export const EDIT_CHAPTER_FAILED: any = 'EDIT_CHAPTER_FAILED';

export const DELETE_CHAPTER: any = 'DELETE_CHAPTER';
export const DELETE_CHAPTER_SUCCEEDED: any = 'DELETE_CHAPTER_SUCCEEDED';
export const DELETE_CHAPTER_FAILED: any = 'DELETE_CHAPTER_FAILED';

export const SET_CHAPTER_DATA: any = 'SET_CHAPTER_DATA';
export const SET_CHAPTER_EDIT_DATA: any = 'SET_CHAPTER_EDIT_DATA';
export const SET_CHAPTER_ERROR_DATA: any = 'SET_CHAPTER_ERROR_DATA';

// landing home
export const GET_HOME: any = 'GET_HOME';
export const GET_HOME_SUCCEEDED: any = 'GET_HOME_SUCCEEDED';
export const GET_HOME_FAILED: any = 'GET_HOME_FAILED';

export const GET_INFO: any = 'GET_INFO';
export const GET_INFO_SUCCEEDED: any = 'GET_INFO_SUCCEEDED';
export const GET_INFO_FAILED: any = 'GET_INFO_FAILED';

export const GET_COMPANY_INFO: any = 'GET_COMPANY_INFO';
export const GET_COMPANY_INFO_SUCCEEDED: any = 'GET_COMPANY_INFO_SUCCEEDED';
export const GET_COMPANY_INFO_FAILED: any = 'GET_COMPANY_INFO_FAILED';

export const ADD_CONTACT: any = 'ADD_CONTACT';
export const ADD_CONTACT_SUCCEEDED: any = 'ADD_CONTACT_SUCCEEDED';
export const ADD_CONTACT_FAILED: any = 'ADD_CONTACT_FAILED';

export const GET_LANDING_FAQ: any = 'GET_LANDING_FAQ';
export const GET_LANDING_FAQ_SUCCEEDED: any = 'GET_LANDING_FAQ_SUCCEEDED';
export const GET_LANDING_FAQ_FAILED: any = 'GET_LANDING_FAQ_FAILED';

export const SET_HOME_DATA: any = 'SET_HOME_DATA';

// Tutor
export const ADD_REQUEST_COURSE_FORM: any = 'ADD_REQUEST_COURSE_FORM';

export const EDIT_TUTOR: any = 'EDIT_TUTOR';
export const EDIT_TUTOR_SUCCEEDED: any = 'EDIT_TUTOR_SUCCEEDED';
export const EDIT_TUTOR_FAILED: any = 'EDIT_TUTOR_FAILED';

export const ADD_REQUEST_COURSE_FORM_SUCCEEDED: any =
  'ADD_REQUEST_COURSE_FORM_SUCCEEDED';
export const ADD_REQUEST_COURSE_FORM_FAILED: any = 'ADD_REQUEST_COURSE_FORM_FAILED';

export const CLEAR_TUTOR_STATE: any = 'CLEAR_TUTOR_STATE';
export const SET_TUTOR_DATA: any = 'SET_TUTOR_DATA';
export const SET_TUTOR_EDIT_DATA: any = 'SET_TUTOR_EDIT_DATA';

export const GET_TUTOR_DETAIL: any = 'GET_TUTOR_DETAIL';
export const GET_TUTOR_DETAIL_SUCCEEDED: any = 'GET_TUTOR_DETAIL_SUCCEEDED';
export const GET_TUTOR_DETAIL_FAILED: any = 'GET_TUTOR_DETAIL_FAILED';

export const ADD_TUTOR_EDUCATION_INFO: any = 'ADD_TUTOR_EDUCATION_INFO';
export const ADD_TUTOR_EDUCATION_INFO_SUCCEEDED: any =
  'ADD_TUTOR_EDUCATION_INFO_SUCCEEDED';
export const ADD_TUTOR_EDUCATION_INFO_FAILED: any =
  'ADD_TUTOR_EDUCATION_INFO_FAILED';

export const EDIT_TUTOR_EDUCATION_INFO: any = 'EDIT_TUTOR_EDUCATION_INFO';
export const EDIT_TUTOR_EDUCATION_INFO_SUCCEEDED: any =
  'EDIT_TUTOR_EDUCATION_INFO_SUCCEEDED';
export const EDIT_TUTOR_EDUCATION_INFO_FAILED: any =
  'EDIT_TUTOR_EDUCATION_INFO_FAILED';

export const DELETE_TUTOR_EDUCATION_INFO: any = 'DELETE_TUTOR_EDUCATION_INFO';
export const DELETE_TUTOR_EDUCATION_INFO_SUCCEEDED: any =
  'DELETE_TUTOR_EDUCATION_INFO_SUCCEEDED';
export const DELETE_TUTOR_EDUCATION_INFO_FAILED: any =
  'DELETE_TUTOR_EDUCATION_INFO_FAILED';

export const ADD_TUTOR_EXPERIENCE_INFO: any = 'ADD_TUTOR_EXPERIENCE_INFO';
export const ADD_TUTOR_EXPERIENCE_INFO_SUCCEEDED: any =
  'ADD_TUTOR_EXPERIENCE_INFO_SUCCEEDED';
export const ADD_TUTOR_EXPERIENCE_INFO_FAILED: any =
  'ADD_TUTOR_EXPERIENCE_INFO_FAILED';

export const EDIT_TUTOR_EXPERIENCE_INFO: any = 'EDIT_TUTOR_EXPERIENCE_INFO';
export const EDIT_TUTOR_EXPERIENCE_INFO_SUCCEEDED: any =
  'EDIT_TUTOR_EXPERIENCE_INFO_SUCCEEDED';
export const EDIT_TUTOR_EXPERIENCE_INFO_FAILED: any =
  'EDIT_TUTOR_EXPERIENCE_INFO_FAILED';

export const DELETE_TUTOR_EXPERIENCE_INFO: any = 'DELETE_TUTOR_EXPERIENCE_INFO';
export const DELETE_TUTOR_EXPERIENCE_INFO_SUCCEEDED: any =
  'DELETE_TUTOR_EXPERIENCE_INFO_SUCCEEDED';
export const DELETE_TUTOR_EXPERIENCE_INFO_FAILED: any =
  'DELETE_TUTOR_EXPERIENCE_INFO_FAILED';

// Admin Dashboard view Request Course Form
export const GET_REQUESTED_COURSE_INQUIRY: any = 'GET_REQUESTED_COURSE_INQUIRY';
export const GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED: any =
  'GET_REQUESTED_COURSE_INQUIRY_SUCCEEDED';
export const GET_REQUESTED_COURSE_INQUIRY_FAILED: any =
  'GET_REQUESTED_COURSE_INQUIRY_FAILED';

//Admin Dashboard view Request Course status update
export const EDIT_REQUESTED_COURSE_INQUIRY_STATUS: any =
  'EDIT_REQUESTED_COURSE_INQUIRY_STATUS';
export const EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED: any =
  'EDIT_REQUESTED_COURSE_INQUIRY_STATUS_SUCCEEDED';
export const EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED: any =
  'EDIT_REQUESTED_COURSE_INQUIRY_STATUS_FAILED';

//Course inquiry comments
export const GET_COURSE_COMMENTS_REQUEST: any = 'GET_COURSE_COMMENTS_REQUEST';
export const GET_COURSE_COMMENTS_SUCCEEDED: any = 'GET_COURSE_COMMENTS_SUCCEEDED';
export const GET_COURSE_COMMENTS_FAILED: any = 'GET_COURSE_COMMENTS_FAILED';

export const ADD_COURSE_COMMENTS_REQUEST: any = 'ADD_COURSE_COMMENTS_REQUEST';
export const ADD_COURSE_COMMENTS_SUCCEEDED: any = 'ADD_COURSE_COMMENTS_SUCCEEDED';
export const ADD_COURSE_COMMENTS_FAILED: any = 'ADD_COURSE_COMMENTS_FAILED';

export const EDIT_COURSE_COMMENTS_REQUEST: any = 'EDIT_COURSE_COMMENTS_REQUEST';
export const EDIT_COURSE_COMMENTS_SUCCEEDED: any = 'EDIT_COURSE_COMMENTS_SUCCEEDED';
export const EDIT_COURSE_COMMENTS_FAILED: any = 'EDIT_COURSE_COMMENTS_FAILED';

export const DELETE_COURSE_COMMENTS_REQUEST: any = 'DELETE_COURSE_COMMENTS_REQUEST';
export const DELETE_COURSE_COMMENTS_SUCCEEDED: any =
  'DELETE_COURSE_COMMENTS_SUCCEEDED';
export const DELETE_COURSE_COMMENTS_FAILED: any = 'DELETE_COURSE_COMMENTS_FAILED';

// Dashboard Analytics user
export const GET_ANALYTICS_USER: any = 'GET_ANALYTICS_USER';
export const GET_ANALYTICS_USER_SUCCEEDED: any = 'GET_ANALYTICS_USER_SUCCEEDED';
export const GET_ANALYTICS_USER_FAILED: any = 'GET_ANALYTICS_USER_FAILED';

// landing blog
export const GET_BLOG: any = 'GET_BLOG';
export const GET_BLOG_SUCCEEDED: any = 'GET_BLOG_SUCCEEDED';
export const GET_BLOG_FAILED: any = 'GET_BLOG_FAILED';

// landing Blog Detail
export const GET_BLOG_DETAIL: any = 'GET_BLOG_DETAIL';
export const GET_BLOG_DETAIL_SUCCEEDED: any = 'GET_BLOG_DETAIL_SUCCEEDED';
export const GET_BLOG_DETAIL_FAILED: any = 'GET_BLOG_DETAIL_FAILED';

// landing Course List
export const GET_COURSE_LIST: any = 'GET_COURSE_LIST';
export const GET_COURSE_LIST_SUCCEEDED: any = 'GET_COURSE_LIST_SUCCEEDED';
export const GET_COURSE_LIST_FAILED: any = 'GET_COURSE_LIST_FAILED';

//landing Course Category List
export const GET_COURSE_CATEGORY_LIST: any = 'GET_COURSE_CATEGORY_LIST';
export const GET_COURSE_CATEGORY_LIST_SUCCEEDED: any =
  'GET_COURSE_CATEGORY_LIST_SUCCEEDED';

export const GET_COURSE_CATEGORY_LIST_FAILED: any =
  'GET_COURSE_CATEGORY_LIST_FAILED';

// landing Course Detail
export const GET_COURSE_DETAIL: any = 'GET_COURSE_DETAIL';
export const GET_COURSE_DETAIL_SUCCEEDED: any = 'GET_COURSE_DETAIL_SUCCEEDED';
export const GET_COURSE_DETAIL_FAILED: any = 'GET_COURSE_DETAIL_FAILED';

export const CLEAR_COURSE_ERROR: any = 'CLEAR_COURSE_ERROR';

// course enrol detail
export const GET_COURSE_ENROL_DETAIL: any = 'GET_COURSE_ENROL_DETAIL';

export const GET_COURSE_ENROL_DETAIL_SUCCEEDED: any =
  'GET_COURSE_ENROL_DETAIL_SUCCEEDED';

export const GET_COURSE_ENROL_DETAIL_FAILED: any = 'GET_COURSE_ENROL_DETAIL_FAILED';

// course enrol detail
export const UPDATE_COURSE_ENROL_DETAIL: any = 'UPDATE_COURSE_ENROL_DETAIL';

export const UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED: any =
  'UPDATE_COURSE_ENROL_DETAIL_SUCCEEDED';

export const UPDATE_COURSE_ENROL_DETAIL_FAILED: any =
  'UPDATE_COURSE_ENROL_DETAIL_FAILED';

export const ADD_COURSE_ENROL_CONTENT: any = 'ADD_COURSE_ENROL_CONTENT';
export const ADD_COURSE_ENROL_CONTENT_SUCCEEDED: any =
  'ADD_COURSE_ENROL_CONTENT_SUCCEEDED';
export const ADD_COURSE_ENROL_CONTENT_FAILED: any =
  'ADD_COURSE_ENROL_CONTENT_FAILED';

// Admin View Enrolled Data
export const GET_COURSE_CLIENT: any = 'GET_COURSE_CLIENT';
export const GET_COURSE_CLIENT_SUCCEEDED: any = 'GET_COURSE_CLIENT_SUCCEEDED';
export const GET_COURSE_CLIENT_FAILED: any = 'GET_COURSE_CLIENT_FAILED';

export const GET_MOCK_TEST_CLIENT: any = 'GET_MOCK_TEST_CLIENT';
export const GET_MOCK_TEST_CLIENT_SUCCEEDED: any = 'GET_MOCK_TEST_CLIENT_SUCCEEDED';
export const GET_MOCK_TEST_CLIENT_FAILED: any = 'GET_MOCK_TEST_CLIENT_FAILED';

export const GET_USER_REVIEWS: any = 'GET_USER_REVIEWS';
export const GET_USER_REVIEWS_SUCCEEDED: any = 'GET_USER_REVIEWS_SUCCEEDED';
export const GET_USER_REVIEWS_FAILED: any = 'GET_USER_REVIEWS_FAILED';

// Course Material
export const GET_COURSE_MATERIAL: any = 'GET_COURSE_MATERIAL';
export const GET_COURSE_MATERIAL_SUCCEEDED: any = 'GET_COURSE_MATERIAL_SUCCEEDED';
export const GET_COURSE_MATERIAL_FAILED: any = 'GET_COURSE_MATERIAL_FAILED';

export const GET_COURSE_MATERIAL_BY_ID: any = 'GET_COURSE_MATERIAL_BY_ID';
export const GET_COURSE_MATERIAL_BY_ID_SUCCEEDED: any =
  'GET_COURSE_MATERIAL_BY_ID_SUCCEEDED';
export const GET_COURSE_MATERIAL_BY_ID_FAILED: any =
  'GET_COURSE_MATERIAL_BY_ID_FAILED';

// Course Rating
export const GET_COURSE_REVIEW: any = 'GET_COURSE_REVIEW';
export const GET_COURSE_REVIEW_SUCCEEDED: any = 'GET_COURSE_REVIEW_SUCCEEDED';
export const GET_COURSE_REVIEW_FAILED: any = 'GET_COURSE_REVIEW_FAILED';

export const GET_COURSE_REVIEW_BY_PAGE: any = 'GET_COURSE_REVIEW_BY_PAGE';
export const GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED: any =
  'GET_COURSE_REVIEW_BY_PAGE_SUCCEEDED';

export const GET_COURSE_REVIEW_BY_PAGE_FAILED: any =
  'GET_COURSE_REVIEW_BY_PAGE_FAILED';

export const ADD_COURSE_REVIEW: any = 'ADD_COURSE_REVIEW';
export const ADD_COURSE_REVIEW_SUCCEEDED: any = 'ADD_COURSE_REVIEW_SUCCEEDED';
export const ADD_COURSE_REVIEW_FAILED: any = 'ADD_COURSE_REVIEW_FAILED';

export const EDIT_COURSE_REVIEW: any = 'EDIT_COURSE_REVIEW';
export const EDIT_COURSE_REVIEW_SUCCEEDED: any = 'EDIT_COURSE_REVIEW_SUCCEEDED';
export const EDIT_COURSE_REVIEW_FAILED: any = 'EDIT_COURSE_REVIEW_FAILED';

export const DELETE_COURSE_REVIEW: any = 'DELETE_COURSE_REVIEW';
export const DELETE_COURSE_REVIEW_SUCCEEDED: any = 'DELETE_COURSE_REVIEW_SUCCEEDED';
export const DELETE_COURSE_REVIEW_FAILED: any = 'DELETE_COURSE_REVIEW_FAILED';

export const LIKE_COURSE_REVIEW: any = 'LIKE_COURSE_REVIEW';
export const LIKE_COURSE_REVIEW_SUCCEEDED: any = 'LIKE_COURSE_REVIEW_SUCCEEDED';
export const LIKE_COURSE_REVIEW_FAILED: any = 'LIKE_COURSE_REVIEW_FAILED';

export const DISLIKE_COURSE_REVIEW: any = 'DISLIKE_COURSE_REVIEW';

export const DISLIKE_COURSE_REVIEW_SUCCEEDED: any =
  'DISLIKE_COURSE_REVIEW_SUCCEEDED';

export const DISLIKE_COURSE_REVIEW_FAILED: any = 'DISLIKE_COURSE_REVIEW_FAILED';

// landing Course Video
export const GET_COURSE_VIDEO: any = 'GET_GET_COURSE_VIDEO';
export const GET_COURSE_VIDEO_SUCCEEDED: any = 'GET_COURSE_VIDEO_SUCCEEDED';
export const GET_COURSE_VIDEO_FAILED: any = 'GET_COURSE_VIDEO_FAILED';

export const UPDATE_COURSE_VIDEO: any = 'UPDATE_COURSE_VIDEO';
export const UPDATE_COURSE_VIDEO_SUCCEEDED: any = 'UPDATE_COURSE_VIDEO_SUCCEEDED';
export const UPDATE_COURSE_VIDEO_FAILED: any = 'UPDATE_COURSE_VIDEO_FAILED';

// landing Course Notes
export const GET_NOTES: any = 'GET_NOTES';
export const GET_NOTES_SUCCEEDED: any = 'GET_NOTES_SUCCEEDED';
export const GET_NOTES_FAILED: any = 'GET_NOTES_FAILED';

export const ADD_NOTES: any = 'ADD_NOTES';
export const ADD_NOTES_SUCCEEDED: any = 'ADD_NOTES_SUCCEEDED';
export const ADD_NOTES_FAILED: any = 'ADD_NOTES_FAILED';

export const EDIT_NOTES: any = 'EDIT_NOTES';
export const EDIT_NOTES_SUCCEEDED: any = 'EDIT_NOTES_SUCCEEDED';
export const EDIT_NOTES_FAILED: any = 'EDIT_NOTES_FAILED';

export const DELETE_NOTES: any = 'DELETE_NOTES';
export const DELETE_NOTES_SUCCEEDED: any = 'DELETE_NOTES_SUCCEEDED';
export const DELETE_NOTES_FAILED: any = 'DELETE_NOTES_FAILED';

// langing course questions

export const GET_QUESTION_DOUBTS: any = 'GET_QUESTION_DOUBTS';
export const GET_QUESTION_DOUBTS_SUCCEEDED: any = 'GET_QUESTION_DOUBTS_SUCCEEDED';
export const GET_QUESTION_DOUBTS_FAILED: any = 'GET_QUESTION_DOUBTS_FAILED';

export const GET_QUESTION_DOUBTS_BY_PAGE: any = 'GET_QUESTION_DOUBTS_BY_PAGE';
export const GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED: any =
  'GET_QUESTION_DOUBTS_BY_PAGE_SUCCEEDED';
export const GET_QUESTION_DOUBTS_BY_PAGE_FAILED: any =
  'GET_QUESTION_DOUBTS_BY_PAGE_FAILED';

export const GET_QUESTION_DOUBT_COMMENT: any = 'GET_QUESTION_DOUBT_COMMENT';
export const GET_QUESTION_DOUBT_COMMENT_SUCCEEDED: any =
  'GET_QUESTION_DOUBT_COMMENT_SUCCEEDED';
export const GET_QUESTION_DOUBT_COMMENT_FAILED: any =
  'GET_QUESTION_DOUBT_COMMENT_FAILED';

export const GET_QUESTION_DOUBT_COMMENT_BY_PAGE: any =
  'GET_QUESTION_DOUBT_COMMENT_BY_PAGE';
export const GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED: any =
  'GET_QUESTION_DOUBT_COMMENT_BY_PAGE_SUCCEEDED';
export const GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED: any =
  'GET_QUESTION_DOUBT_COMMENT_BY_PAGE_FAILED';

export const ADD_QUESTION_DOUBT_COMMENT: any = 'ADD_QUESTION_DOUBT_COMMENT';
export const ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED: any =
  'ADD_QUESTION_DOUBT_COMMENT_SUCCEEDED';
export const ADD_QUESTION_DOUBT_COMMENT_FAILED: any =
  'ADD_QUESTION_DOUBT_COMMENT_FAILED';

export const LIKE_QUESTION_DOUBT_COMMENT: any = 'LIKE_QUESTION_DOUBT_COMMENT';
export const LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED: any =
  'LIKE_QUESTION_DOUBT_COMMENT_SUCCEEDED';
export const LIKE_QUESTION_DOUBT_COMMENT_FAILED: any =
  'LIKE_QUESTION_DOUBT_COMMENT_FAILED';

export const EDIT_QUESTION_DOUBT_COMMENT: any = 'EDIT_QUESTION_DOUBT_COMMENT';
export const EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED: any =
  'EDIT_QUESTION_DOUBT_COMMENT_SUCCEEDED';
export const EDIT_QUESTION_DOUBT_COMMENT_FAILED: any =
  'EDIT_QUESTION_DOUBT_COMMENT_FAILED';

export const DELETE_QUESTION_DOUBT_COMMENT: any = 'DELETE_QUESTION_DOUBT_COMMENT';
export const DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED: any =
  'DELETE_QUESTION_DOUBT_COMMENT_SUCCEEDED';
export const DELETE_QUESTION_DOUBT_COMMENT_FAILED: any =
  'DELETE_QUESTION_DOUBT_COMMENT_FAILED';

export const GET_QUESTION_DOUBTS_DETAIL: any = 'GET_QUESTION_DOUBTS_DETAIL';
export const GET_QUESTION_DOUBTS_DETAIL_SUCCEEDED: any =
  'GET_QUESTION_DOUBTS_DETAIL_SUCCEEDED';
export const GET_QUESTION_DOUBTS_DETAIL_FAILED: any =
  'GET_QUESTION_DOUBTS_DETAIL_FAILED';

export const ADD_QUESTION_DOUBTS: any = 'ADD_QUESTION_DOUBTS';
export const ADD_QUESTION_DOUBTS_SUCCEEDED: any = 'ADD_QUESTION_DOUBTS_SUCCEEDED';
export const ADD_QUESTION_DOUBTS_FAILED: any = 'ADD_QUESTION_DOUBTS_FAILED';

export const EDIT_QUESTION_DOUBTS: any = 'EDIT_QUESTION_DOUBTS';
export const EDIT_QUESTION_DOUBTS_SUCCEEDED: any = 'EDIT_QUESTION_DOUBTS_SUCCEEDED';
export const EDIT_QUESTION_DOUBTS_FAILED: any = 'EDIT_QUESTION_DOUBTS_FAILED';

export const DELETE_QUESTION_DOUBTS: any = 'DELETE_QUESTION_DOUBTS';
export const DELETE_QUESTION_DOUBTS_SUCCEEDED: any =
  'DELETE_QUESTION_DOUBTS_SUCCEEDED';
export const DELETE_QUESTION_DOUBTS_FAILED: any = 'DELETE_QUESTION_DOUBTS_FAILED';

export const LIKE_COURSE_QUESTION: any = 'LIKE_COURSE_QUESTION';
export const LIKE_COURSE_QUESTION_SUCCEEDED: any = 'LIKE_COURSE_QUESTION_SUCCEEDED';
export const LIKE_COURSE_QUESTION_FAILED: any = 'LIKE_COURSE_QUESTION_FAILED';

// filter
export const DELETE_VALUE: any = 'DELETE_VALUE';
export const VALUE_CHANGED: any = 'VALUE_CHANGED';

//content
export const GET_CONTENTS: any = 'GET_CONTENTS';
export const GET_CONTENTS_SUCCEEDED: any = 'GET_CONTENTS_SUCCEEDED';
export const GET_CONTENTS_FAILED: any = 'GET_CONTENTS_FAILED';

export const GET_CONTENT_BY_SLUG: any = 'GET_CONTENT_BY_SLUG';
export const GET_CONTENT_BY_SLUG_SUCCEEDED: any = 'GET_CONTENT_BY_SLUG_SUCCEEDED';
export const GET_CONTENT_BY_SLUG_FAILED: any = 'GET_CONTENT_BY_SLUG_FAILED';

export const ADD_CONTENT: any = 'ADD_CONTENT';
export const ADD_CONTENT_SUCCEEDED: any = 'ADD_CONTENT_SUCCEEDED';
export const ADD_CONTENT_FAILED: any = 'ADD_CONTENT_FAILED';

export const EDIT_CONTENT: any = 'EDIT_CONTENT';
export const EDIT_CONTENT_SUCCEEDED: any = 'EDIT_CONTENT_SUCCEEDED';
export const EDIT_CONTENT_FAILED: any = 'EDIT_CONTENT_FAILED';

export const ADD_CONTENT_FILE: any = 'ADD_CONTENT_FILE';
export const ADD_CONTENT_FILE_SUCCEEDED: any = 'ADD_CONTENT_FILE_SUCCEEDED';
export const ADD_CONTENT_FILE_FAILED: any = 'ADD_CONTENT_FILE_FAILED';

export const EDIT_CONTENT_FILE: any = 'EDIT_CONTENT_FILE';
export const EDIT_CONTENT_FILE_SUCCEEDED: any = 'EDIT_CONTENT_FILE_SUCCEEDED';
export const EDIT_CONTENT_FILE_FAILED: any = 'EDIT_CONTENT_FILE_FAILED';

export const DELETE_CONTENT_FILE: any = 'DELETE_CONTENT_FILE';
export const DELETE_CONTENT_FILE_SUCCEEDED: any = 'DELETE_CONTENT_FILE_SUCCEEDED';
export const DELETE_CONTENT_FILE_FAILED: any = 'DELETE_CONTENT_FILE_FAILED';

// Doubt

export const GET_COMMENT: any = 'GET_COMMENT';
export const ADD_COMMENT: any = 'ADD_COMMENT';

export const DELETE_CONTENT: any = 'DELETE_CONTENT';
export const DELETE_CONTENT_SUCCEEDED: any = 'DELETE_CONTENT_SUCCEEDED';
export const DELETE_CONTENT_FAILED: any = 'DELETE_CONTENT_FAILED';

export const SET_CONTENT_EDIT_DATA: any = 'SET_CONTENT_EDIT_DATA';
export const SET_CONTENT_DATA: any = 'SET_CONTENT_DATA';

export const GET_VIDEO_BY_SLUG: any = 'GET_VIDEO_BY_SLUG';
export const GET_VIDEO_BY_SLUG_SUCCEEDED: any = 'GET_VIDEO_BY_SLUG_SUCCEEDED';
export const GET_VIDEO_BY_SLUG_FAILED: any = 'GET_VIDEO_BY_SLUG_FAILED';

export const ADD_VIDEO: any = 'ADD_VIDEO';
export const ADD_VIDEO_SUCCEEDED: any = 'ADD_VIDEO_SUCCEEDED';
export const ADD_VIDEO_FAILED: any = 'ADD_VIDEO_FAILED';
export const CLEAR_VIDEO_DATA: any = 'CLEAR_VIDEO_DATA';

export const GET_COURSE_ENROLL: any = 'GET_COURSE_ENROLL';
export const GET_COURSE_ENROLL_SUCCEEDED: any = 'GET_COURSE_ENROLL_SUCCEEDED';
export const GET_COURSE_ENROLL_FAILED: any = 'GET_COURSE_ENROLL_FAILED';

// Related Course
export const GET_RELATED_COURSE: any = 'GET_RELATED_COURSE';
export const GET_RELATED_COURSE_SUCCEEDED: any = 'GET_RELATED_COURSE_SUCCEEDED';
export const GET_RELATED_COURSE_FAILED: any = 'GET_RELATED_COURSE_FAILED';

export const ADD_COURSE_ENROLL: any = 'ADD_COURSE_ENROLL';
export const ADD_COURSE_ENROLL_SUCCEEDED: any = 'ADD_COURSE_ENROLL_SUCCEEDED';
export const ADD_COURSE_ENROLL_FAILED: any = 'ADD_COURSE_ENROLL_FAILED';

// Search Doubt questions
export const SEARCH_DOUBT_QUESTION: any = 'SEARCH_DOUBT_QUESTION';

export const SEARCH_DOUBT_QUESTION_SUCCEEDED: any =
  'SEARCH_DOUBT_QUESTION_SUCCEEDED';

export const SEARCH_DOUBT_QUESTION_FAILED: any = 'SEARCH_DOUBT_QUESTION_FAILED ';

// User Doubts

export const GET_USER_DOUBTS: any = 'GET_USER_DOUBTS';
export const GET_USER_DOUBTS_SUCCEEDED: any = 'GET_USER_DOUBTS_SUCCEEDED';
export const GET_USER_DOUBTS_FAILED: any = 'GET_USER_DOUBTS_FAILED';

// quiz
export const GET_QUESTION: any = 'GET_QUESTION';
export const GET_QUESTION_SUCCEDED: any = 'GET_QUESTION_SUCCEDED';
export const GET_QUESTION_FAIL: any = 'GET_QUESTION_FAIL';

export const ADD_QUIZ_ANSWER_SHEET: any = 'ADD_QUIZ_ANSWER_SHEET';
export const ADD_QUIZ_ANSWER_SHEET_SUCCEEDED: any =
  'ADD_QUIZ_ANSWER_SHEET_SUCCEEDED';
export const ADD_QUIZ_ANSWER_SHEET_FAILED: any = 'ADD_QUIZ_ANSWER_SHEET_FAILED';

export const GET_QUIZ_ANSWER_SHEET: any = 'GET_QUIZ_ANSWER_SHEET';
export const GET_QUIZ_ANSWER_SHEET_SUCCEEDED: any =
  'GET_QUIZ_ANSWER_SHEET_SUCCEEDED';
export const GET_QUIZ_ANSWER_SHEET_FAILED: any = 'GET_QUIZ_ANSWER_SHEET_FAILED';

export const GET_QUIZ_SUMMARY: any = 'GET_QUIZ_SUMMARY';
export const GET_QUIZ_SUMMARY_SUCCEEDED: any = 'GET_QUIZ_SUMMARY_SUCCEEDED';
export const GET_QUIZ_SUMMARY_FAILED: any = 'GET_QUIZ_SUMMARY_FAILED';

export const SET_QUIZ_EDIT_DATA: any = 'SET_QUIZ_EDIT_DATA';
export const SET_QUIZ_COMPLETE_DATA: any = 'SET_QUIZ_COMPLETE_DATA';

// Mock test section

export const TOGGLE_BOTTOM_PALETTE: any = 'TOGGLE_BOTTOM_PALETTE';

export const GET_MY_ATTEMPTED_TESTS: any = 'GET_MY_ATTEMPTED_TESTS';

export const GET_MY_ATTEMPTED_TESTS_SUCCEEDED: any =
  'GET_MY_ATTEMPTED_TESTS_SUCCEEDED';

export const GET_MY_ATTEMPTED_TESTS_FAILED: any = 'GET_MY_ATTEMPTED_TESTS_FAILED';

export const ENROL_MOCK_TEST: any = 'ENROL_MOCK_TEST';
export const ENROL_MOCK_TEST_SUCCEEDED: any = 'ENROL_MOCK_TEST_SUCCEEDED';
export const ENROL_MOCK_TEST_FAILED: any = 'ENROL_MOCK_TEST_FAILED';

// Doubt actions
export const GET_DOUBT_POST: any = 'GET_DOUBT_POST';
export const GET_DOUBT_POST_SUCCEEDED: any = 'GET_DOUBT_POST_SUCCEEDED';
export const GET_DOUBT_POST_FAILED: any = 'GET_DOUBT_POST_FAILED';

export const GET_DOUBT_POST_BY_PAGE: any = 'GET_DOUBT_POST_BY_PAGE';

export const GET_DOUBT_POST_BY_PAGE_SUCCEEDED: any =
  'GET_DOUBT_POST_BY_PAGE_SUCCEEDED';
export const GET_DOUBT_POST_BY_PAGE_FAILED: any = 'GET_DOUBT_POST_BY_PAGE_FAILED';

export const GET_DOUBT_DETAIL_POST: any = 'GET_DOUBT_DETAIL_POST';
export const GET_DOUBT_DETAIL_POST_SUCCEEDED: any =
  'GET_DOUBT_DETAIL_POST_SUCCEEDED';

export const GET_DOUBT_DETAIL_POST_FAILED: any = 'GET_DOUBT_DETAIL_POST_FAILED';

export const GET_DOUBT_COMMENT: any = 'GET_DOUBT_COMMENT';
export const GET_DOUBT_COMMENT_SUCCEEDED: any = 'GET_DOUBT_COMMENT_SUCCEEDED';
export const GET_DOUBT_COMMENT_FAILED: any = 'GET_DOUBT_COMMENT_FAILED';

export const GET_DOUBT_COMMENT_FOR_DETAIL_PAGE: any =
  'GET_DOUBT_COMMENT_FOR_DETAIL_PAGE';

export const GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_SUCCEEDED: any =
  'GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_SUCCEEDED';
export const GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_FAILED: any =
  'GET_DOUBT_COMMENT_FOR_DETAIL_PAGE_FAILED';

export const ADD_DOUBT_COMMENT: any = 'ADD_DOUBT_COMMENT';
export const ADD_DOUBT_COMMENT_SUCCEEDED: any = 'ADD_DOUBT_COMMENT_SUCCEEDED';
export const ADD_DOUBT_COMMENT_FAILED: any = 'ADD_DOUBT_COMMENT_FAILED';

export const LIKE_DOUBT_COMMENT: any = 'LIKE_DOUBT_COMMENT';
export const LIKE_DOUBT_COMMENT_SUCCEEDED: any = 'LIKE_DOUBT_COMMENT_SUCCEEDED';
export const LIKE_DOUBT_COMMENT_FAILED: any = 'LIKE_DOUBT_COMMENT_FAILED';

export const ADD_DOUBT_POST: any = 'ADD_DOUBT_POST';
export const ADD_DOUBT_POST_SUCCEEDED: any = 'ADD_DOUBT_POST_SUCCEEDED';
export const ADD_DOUBT_POST_FAILED: any = 'ADD_DOUBT_POST_FAILED';

export const ADD_DOUBT_POLL: any = 'ADD_DOUBT_POLL';
export const ADD_DOUBT_POLL_SUCCEEDED: any = 'ADD_DOUBT_POLL_SUCCEEDED';
export const ADD_DOUBT_POLL_FAILED: any = 'ADD_DOUBT_POLL_FAILED';

export const VOTE_DOUBT_POLL: any = 'VOTE_DOUBT_POLL';
export const VOTE_DOUBT_POLL_SUCCEEDED: any = 'VOTE_DOUBT_POLL_SUCCEEDED';
export const VOTE_DOUBT_POLL_FAILED: any = 'VOTE_DOUBT_POLL_FAILED';

export const EDIT_DOUBT_COMMENT: any = 'EDIT_DOUBT_COMMENT';
export const EDIT_DOUBT_COMMENT_SUCCEEDED: any = 'EDIT_DOUBT_COMMENT_SUCCEEDED';
export const EDIT_DOUBT_COMMENT_FAILED: any = 'EDIT_DOUBT_COMMENT_FAILED';

export const DELETE_DOUBT_COMMENT: any = 'DELETE_DOUBT_COMMENT';
export const DELETE_DOUBT_COMMENT_SUCCEEDED: any = 'DELETE_DOUBT_COMMENT_SUCCEEDED';
export const DELETE_DOUBT_COMMENT_FAILED: any = 'DELETE_DOUBT_COMMENT_FAILED';

export const REPORT_DOUBT_COMMENT: any = 'REPORT_DOUBT_COMMENT';
export const REPORT_DOUBT_COMMENT_SUCCEEDED: any = 'REPORT_DOUBT_COMMENT_SUCCEEDED';
export const REPORT_DOUBT_COMMENT_FAILED: any = 'REPORT_DOUBT_COMMENT_FAILED';

export const EDIT_DOUBT_POST: any = 'EDIT_DOUBT_POST';
export const EDIT_DOUBT_POST_SUCCEEDED: any = 'EDIT_DOUBT_POST_SUCCEEDED';
export const EDIT_DOUBT_POST_FAILED: any = 'EDIT_DOUBT_POST_FAILED';

export const GET_DOUBT_TOPIC: any = 'GET_DOUBT_TOPIC';
export const GET_DOUBT_TOPIC_SUCCEEDED: any = 'GET_DOUBT_TOPIC_SUCCEEDED';
export const GET_DOUBT_TOPIC_FAILED: any = 'GET_DOUBT_TOPIC_FAILED';

export const ADD_DOUBT_TOPIC: any = 'ADD_DOUBT_TOPIC';
export const ADD_DOUBT_TOPIC_SUCCEEDED: any = 'ADD_DOUBT_TOPIC_SUCCEEDED';
export const ADD_DOUBT_TOPIC_FAILED: any = 'ADD_DOUBT_TOPIC_FAILED';

// Top TOPICS
export const GET_TOP_TOPICS: any = 'GET_TOP_TOPICS';
export const GET_TOP_TOPICS_SUCCEEDED: any = 'GET_TOP_TOPICS_SUCCEEDED';
export const GET_TOP_TOPICS_FAILED: any = 'GET_TOP_TOPICS_FAILED';

export const REPORT_DOUBT_POST: any = 'REPORT_DOUBT_POST';
export const REPORT_DOUBT_POST_SUCCEEDED: any = 'REPORT_DOUBT_POST_SUCCEEDED';
export const REPORT_DOUBT_POST_FAILED: any = 'REPORT_DOUBT_POST_FAILED';

// Admin doubts
export const GET_POST_REPORTS: any = 'GET_POST_REPORTS';
export const GET_POST_REPORTS_SUCCEEDED: any = 'GET_POST_REPORTS_SUCCEEDED';
export const GET_POST_REPORTS_FAILED: any = 'GET_POST_REPORTS_FAILED';

export const EDIT_POST_REPORTS: any = 'EDIT_POST_REPORTS';
export const EDIT_POST_REPORTS_SUCCEEDED: any = 'EDIT_POST_REPORTS_SUCCEEDED';
export const EDIT_POST_REPORTS_FAILED: any = 'EDIT_POST_REPORTS_FAILED';

export const GET_COMMENT_REPORTS: any = 'GET_COMMENT_REPORTS';
export const GET_COMMENT_REPORTS_SUCCEEDED: any = 'GET_COMMENT_REPORTS_SUCCEEDED';
export const GET_COMMENT_REPORTS_FAILED: any = 'GET_COMMENT_REPORTS_FAILED';

export const EDIT_COMMENT_REPORTS: any = 'EDIT_COMMENT_REPORTS';
export const EDIT_COMMENT_REPORTS_SUCCEEDED: any = 'EDIT_COMMENT_REPORTS_SUCCEEDED';
export const EDIT_COMMENT_REPORTS_FAILED: any = 'EDIT_COMMENT_REPORTS_FAILED';

export const EDIT_COMMENT_ADMIN: any = 'EDIT_COMMENT_ADMIN';
export const EDIT_COMMENT_ADMIN_SUCCEEDED: any = 'EDIT_COMMENT_ADMIN_SUCCEEDED';
export const EDIT_COMMENT_ADMIN_FAILED: any = 'EDIT_COMMENT_ADMIN_FAILED';

export const GET_POST_REQUESTS: any = 'GET_POST_REQUESTS';
export const GET_POST_REQUESTS_SUCCEEDED: any = 'GET_POST_REQUESTS_SUCCEEDED';
export const GET_POST_REQUESTS_FAILED: any = 'GET_POST_REQUESTS_FAILED';

export const EDIT_DOUBT_REQUESTS_ADMIN: any = 'EDIT_DOUBT_REQUESTS_ADMIN';
export const EDIT_DOUBT_REQUESTS_ADMIN_SUCCEEDED: any =
  'EDIT_DOUBT_REQUESTS_ADMIN_SUCCEEDED';

export const EDIT_DOUBT_REQUESTS_ADMIN_FAILED: any =
  'EDIT_DOUBT_REQUESTS_ADMIN_FAILED';

// Latest 3 Courses

export const LINK_COPIED: any = 'LINK_COPIED';

export const GET_LATEST_COURSE: any = 'GET_LATEST_COURSE';
export const GET_LATEST_COURSE_SUCCEEDED: any = 'GET_LATEST_COURSE_SUCCEEDED';
export const GET_LATEST_COURSE_FAILED: any = 'GET_LATEST_COURSE_FAILED';

export const DELETE_DOUBT_POST: any = 'DELETE_DOUBT_POST';
export const DELETE_DOUBT_POST_SUCCEEDED: any = 'DELETE_DOUBT_POST_SUCCEEDED';
export const DELETE_DOUBT_POST_FAILED: any = 'DELETE_DOUBT_POST_FAILED';

export const ADD_DOUBT_POST_LIKE: any = 'ADD_DOUBT_POST_LIKE';
export const ADD_DOUBT_POST_LIKE_SUCCEEDED: any = 'ADD_DOUBT_POST_LIKE_SUCCEEDED';
export const ADD_DOUBT_POST_LIKE_FAILED: any = 'ADD_DOUBT_POST_LIKE_FAILED';

//model test
export const GET_MODEL_TESTS: any = 'GET_MODEL_TESTS';
export const GET_MODEL_TESTS_SUCCEEDED: any = 'GET_MODEL_TESTS_SUCCEEDED';
export const GET_MODEL_TESTS_FAILED: any = 'GET_MODEL_TESTS_FAILED';

export const SET_MODEL_TESTS_EDIT_DATA: any = 'SET_MODEL_TESTS_EDIT_DATA';

export const GET_MODEL_TEST_BY_SLUG: any = 'GET_MODEL_TEST_BY_SLUG';
export const GET_MODEL_TEST_BY_SLUG_SUCCEEDED: any =
  'GET_MODEL_TEST_BY_SLUG_SUCCEEDED';
export const GET_MODEL_TEST_BY_SLUG_FAILED: any = 'GET_MODEL_TEST_BY_SLUG_FAILED';

export const ADD_MODEL_TEST: any = 'ADD_MODEL_TEST';
export const ADD_MODEL_TEST_SUCCEEDED: any = 'ADD_MODEL_TEST_SUCCEEDED';
export const ADD_MODEL_TEST_FAILED: any = 'ADD_MODEL_TEST_FAILED';

export const EDIT_MODEL_TEST: any = 'EDIT_MODEL_TEST';
export const EDIT_MODEL_TEST_SUCCEEDED: any = 'EDIT_MODEL_TEST_SUCCEEDED';
export const EDIT_MODEL_TEST_FAILED: any = 'EDIT_MODEL_TEST_FAILED';

export const DELETE_MODEL_TEST: any = 'DELETE_MODEL_TEST';
export const DELETE_MODEL_TEST_SUCCEEDED: any = 'DELETE_MODEL_TEST_SUCCEEDED';
export const DELETE_MODEL_TEST_FAILED: any = 'DELETE_MODEL_TEST_FAILED';

export const SET_MODEL_TEST_DATA: any = 'SET_MODEL_TEST_DATA';
export const SET_MODEL_TEST_EDIT_DATA: any = 'SET_MODEL_TEST_EDIT_DATA';
export const SET_MODEL_TEST_ERROR_DATA: any = 'SET_MODEL_TEST_ERROR_DATA';

//model test> clients
export const GET_MODEL_TEST_CLIENTS: any = 'GET_MODEL_TEST_CLIENTS';
export const GET_MODEL_TEST_CLIENTS_SUCCEEDED: any =
  'GET_MODEL_TEST_CLIENTS_SUCCEEDED';
export const GET_MODEL_TEST_CLIENTS_FAILED: any = 'GET_MODEL_TEST_CLIENTS_FAILED';

export const ADD_MODEL_TEST_CLIENT: any = 'ADD_MODEL_TEST_CLIENT';
export const ADD_MODEL_TEST_CLIENT_SUCCEEDED: any =
  'ADD_MODEL_TEST_CLIENT_SUCCEEDED';
export const ADD_MODEL_TEST_CLIENT_FAILED: any = 'ADD_MODEL_TEST_CLIENT_FAILED';

export const CLEAR_MODEL_TEST_STATUS: any = 'CLEAR_MODEL_TEST_STATUS';

export const EDIT_MODEL_TEST_CLIENT: any = 'EDIT_MODEL_TEST_CLIENT';
export const EDIT_MODEL_TEST_CLIENT_SUCCEEDED: any =
  'EDIT_MODEL_TEST_CLIENT_SUCCEEDED';
export const EDIT_MODEL_TEST_CLIENT_FAILED: any = 'EDIT_MODEL_TEST_CLIENT_FAILED';

export const DELETE_MODEL_TEST_CLIENT: any = 'DELETE_MODEL_TEST_CLIENT';
export const DELETE_MODEL_TEST_CLIENT_SUCCEEDED: any =
  'DELETE_MODEL_TEST_CLIENT_SUCCEEDED';
export const DELETE_MODEL_TEST_CLIENT_FAILED: any =
  'DELETE_MODEL_TEST_CLIENT_FAILED';

//model test > section
export const GET_MODEL_TEST_SECTIONS: any = 'GET_MODEL_TEST_SECTIONS';

export const GET_MODEL_TEST_SECTIONS_SUCCEEDED: any =
  'GET_MODEL_TEST_SECTIONS_SUCCEEDED';
export const GET_MODEL_TEST_SECTIONS_FAILED: any = 'GET_MODEL_TEST_SECTIONS_FAILED';

export const GET_MODEL_SECTION_BY_ID: any = 'GET_MODEL_SECTION_BY_ID';
export const GET_MODEL_SECTION_BY_ID_SUCCEEDED: any =
  'GET_MODEL_SECTION_BY_ID_SUCCEEDED';
export const GET_MODEL_SECTION_BY_ID_FAILED: any = 'GET_MODEL_SECTION_BY_ID_FAILED';

export const ADD_MODEL_TEST_SECTION: any = 'ADD_MODEL_TEST_SECTION';
export const ADD_MODEL_TEST_SECTION_SUCCEEDED: any =
  'ADD_MODEL_TEST_SECTION_SUCCEEDED';
export const ADD_MODEL_TEST_SECTION_FAILED: any = 'ADD_MODEL_TEST_SECTION_FAILED';

export const EDIT_MODEL_TEST_SECTION: any = 'EDIT_MODEL_TEST_SECTION';
export const EDIT_MODEL_TEST_SECTION_SUCCEEDED: any =
  'EDIT_MODEL_TEST_SECTION_SUCCEEDED';

export const EDIT_MODEL_TEST_SECTION_FAILED: any = 'EDIT_MODEL_TEST_SECTION_FAILED';

export const DELETE_MODEL_TEST_SECTION: any = 'DELETE_MODEL_TEST_SECTION';
export const DELETE_MODEL_TEST_SECTION_SUCCEEDED: any =
  'DELETE_MODEL_TEST_SECTION_SUCCEEDED';

export const DELETE_MODEL_TEST_SECTION_FAILED: any =
  'DELETE_MODEL_TEST_SECTION_FAILED';
export const SET_MODEL_TEST_SECTION_DATA: any = 'SET_MODEL_TEST_SECTION_DATA';

//model test > questions
export const GET_QUESTIONS: any = 'GET_QUESTIONS';
export const GET_QUESTIONS_SUCCEEDED: any = 'GET_QUESTIONS_SUCCEEDED';
export const GET_QUESTIONS_FAILED: any = 'GET_QUESTIONS_FAILED';

export const GET_MODEL_TEST_QUESTION_BY_ID: any = 'GET_MODEL_TEST_QUESTION_BY_ID';
export const GET_MODEL_TEST_QUESTION_BY_ID_SUCCEEDED: any =
  'GET_MODEL_TEST_QUESTION_BY_ID_SUCCEEDED';
export const GET_MODEL_TEST_QUESTION_BY_ID_FAILED: any =
  'GET_MODEL_TEST_QUESTION_BY_ID_FAILED';

export const ADD_QUESTION: any = 'ADD_QUESTION';
export const ADD_QUESTION_SUCCEEDED: any = 'ADD_QUESTION_SUCCEEDED';
export const ADD_QUESTION_FAILED: any = 'ADD_QUESTION_FAILED';

export const EDIT_QUESTION: any = 'EDIT_QUESTION';
export const EDIT_QUESTION_SUCCEEDED: any = 'EDIT_QUESTION_SUCCEEDED';
export const EDIT_QUESTION_FAILED: any = 'EDIT_QUESTION_FAILED';

export const DELETE_QUESTION: any = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCEEDED: any = 'DELETE_QUESTION_SUCCEEDED';
export const DELETE_QUESTION_FAILED: any = 'DELETE_QUESTION_FAILED';

export const SET_QUESTION_DATA: any = 'SET_QUESTION_DATA';
export const SET_QUESTION_EDIT_DATA: any = 'SET_QUESTION_EDIT_DATA';
export const SET_QUESTION_ERROR_DATA: any = 'SET_QUESTION_ERROR_DATA';

//model test > answers
export const GET_ANSWERS: any = 'GET_ANSWERS';
export const GET_ANSWERS_SUCCEEDED: any = 'GET_ANSWERS_SUCCEEDED';
export const GET_ANSWERS_FAILED: any = 'GET_ANSWERS_FAILED';

export const ADD_ANSWER: any = 'ADD_ANSWER';
export const ADD_ANSWER_SUCCEEDED: any = 'ADD_ANSWER_SUCCEEDED';
export const ADD_ANSWER_FAILED: any = 'ADD_ANSWER_FAILED';

export const EDIT_ANSWER: any = 'EDIT_ANSWER';
export const EDIT_ANSWER_SUCCEEDED: any = 'EDIT_ANSWER_SUCCEEDED';
export const EDIT_ANSWER_FAILED: any = 'EDIT_ANSWER_FAILED';

export const DELETE_ANSWER: any = 'DELETE_ANSWER';
export const DELETE_ANSWER_SUCCEEDED: any = 'DELETE_ANSWER_SUCCEEDED';
export const DELETE_ANSWER_FAILED: any = 'DELETE_ANSWER_FAILED';

//model test > group answers
export const GET_GROUP_ANSWERS: any = 'GET_GROUP_ANSWERS';
export const GET_GROUP_ANSWERS_SUCCEEDED: any = 'GET_GROUP_ANSWERS_SUCCEEDED';
export const GET_GROUP_ANSWERS_FAILED: any = 'GET_GROUP_ANSWERS_FAILED';

export const ADD_GROUP_ANSWER: any = 'ADD_GROUP_ANSWER';
export const ADD_GROUP_ANSWER_SUCCEEDED: any = 'ADD_GROUP_ANSWER_SUCCEEDED';
export const ADD_GROUP_ANSWER_FAILED: any = 'ADD_GROUP_ANSWER_FAILED';

export const EDIT_GROUP_ANSWER: any = 'EDIT_GROUP_ANSWER';
export const EDIT_GROUP_ANSWER_SUCCEEDED: any = 'EDIT_GROUP_ANSWER_SUCCEEDED';
export const EDIT_GROUP_ANSWER_FAILED: any = 'EDIT_GROUP_ANSWER_FAILED';

export const DELETE_GROUP_ANSWER: any = 'DELETE_GROUP_ANSWER';
export const DELETE_GROUP_ANSWER_SUCCEEDED: any = 'DELETE_GROUP_ANSWER_SUCCEEDED';
export const DELETE_GROUP_ANSWER_FAILED: any = 'DELETE_GROUP_ANSWER_FAILED';

export const CLEAR_GROUP_ANSWER: any = 'CLEAR_GROUP_ANSWER';

//course content quiz

export const GET_QUIZ: any = 'GET_QUIZ';
export const GET_QUIZ_SUCCEEDED: any = 'GET_QUIZ_SUCCEEDED';
export const GET_QUIZ_FAILED: any = 'GET_QUIZ_FAILED';

export const GET_QUIZ_BY_SLUG: any = 'GET_QUIZ_BY_SLUG';
export const GET_QUIZ_BY_SLUG_SUCCEEDED: any = 'GET_QUIZ_BY_SLUG_SUCCEEDED';
export const GET_QUIZ_BY_SLUG_FAILED: any = 'GET_QUIZ_BY_SLUG_FAILED';

export const ADD_QUIZ: any = 'ADD_QUIZ';
export const ADD_QUIZ_SUCCEEDED: any = 'ADD_QUIZ_SUCCEEDED';
export const ADD_QUIZ_FAILED: any = 'ADD_QUIZ_FAILED';

export const EDIT_QUIZ: any = 'EDIT_QUIZ';
export const EDIT_QUIZ_SUCCEEDED: any = 'EDIT_QUIZ_SUCCEEDED';
export const EDIT_QUIZ_FAILED: any = 'EDIT_QUIZ_FAILED';

export const DELETE_QUIZ: any = 'DELETE_QUIZ';
export const DELETE_QUIZ_SUCCEEDED: any = 'DELETE_QUIZ_SUCCEEDED';
export const DELETE_QUIZ_FAILED: any = 'DELETE_QUIZ_FAILED';

export const SET_QUIZ_DATA: any = 'SET_QUIZ_DATA';

//quiz questions
export const GET_QUIZ_QUESTIONS: any = 'GET_QUIZ_QUESTIONS';
export const GET_QUIZ_QUESTIONS_SUCCEEDED: any = 'GET_QUIZ_QUESTIONS_SUCCEEDED';
export const GET_QUIZ_QUESTIONS_FAILED: any = 'GET_QUIZ_QUESTIONS_FAILED';

export const ADD_QUIZ_QUESTION: any = 'ADD_QUIZ_QUESTION';
export const ADD_QUIZ_QUESTION_SUCCEEDED: any = 'ADD_QUIZ_QUESTION_SUCCEEDED';
export const ADD_QUIZ_QUESTION_FAILED: any = 'ADD_QUIZ_QUESTION_FAILED';

export const EDIT_QUIZ_QUESTION: any = 'EDIT_QUIZ_QUESTION';
export const EDIT_QUIZ_QUESTION_SUCCEEDED: any = 'EDIT_QUIZ_QUESTION_SUCCEEDED';
export const EDIT_QUIZ_QUESTION_FAILED: any = 'EDIT_QUIZ_QUESTION_FAILED';

export const DELETE_QUIZ_QUESTION: any = 'DELETE_QUIZ_QUESTION';
export const DELETE_QUIZ_QUESTION_SUCCEEDED: any = 'DELETE_QUIZ_QUESTION_SUCCEEDED';
export const DELETE_QUIZ_QUESTION_FAILED: any = 'DELETE_QUIZ_QUESTION_FAILED';

export const CLEAR_QUIZ_QUESTION: any = 'CLEAR_QUIZ_QUESTION';

export const CHANGE_QUIZ_STATE: any = 'CHANGE_QUIZ_STATE';

//quiz answers
export const GET_QUIZ_ANSWERS: any = 'GET_QUIZ_ANSWERS';
export const GET_QUIZ_ANSWERS_SUCCEEDED: any = 'GET_QUIZ_ANSWERS_SUCCEEDED';
export const GET_QUIZ_ANSWERS_FAILED: any = 'GET_QUIZ_ANSWERS_FAILED';

export const ADD_QUIZ_ANSWER: any = 'ADD_QUIZ_ANSWER';
export const ADD_QUIZ_ANSWER_SUCCEEDED: any = 'ADD_QUIZ_ANSWER_SUCCEEDED';
export const ADD_QUIZ_ANSWER_FAILED: any = 'ADD_QUIZ_ANSWER_FAILED';

export const EDIT_QUIZ_ANSWER: any = 'EDIT_QUIZ_ANSWER';
export const EDIT_QUIZ_ANSWER_SUCCEEDED: any = 'EDIT_QUIZ_ANSWER_SUCCEEDED';
export const EDIT_QUIZ_ANSWER_FAILED: any = 'EDIT_QUIZ_ANSWER_FAILED';

export const DELETE_QUIZ_ANSWER: any = 'DELETE_QUIZ_ANSWER';
export const DELETE_QUIZ_ANSWER_SUCCEEDED: any = 'DELETE_QUIZ_ANSWER_SUCCEEDED';
export const DELETE_QUIZ_ANSWER_FAILED: any = 'DELETE_QUIZ_ANSWER_FAILED';

// CRUD Advertisement for Admin
export const GET_ADVERTISEMENT: any = 'GET_ADVERTISEMENT';
export const GET_ADVERTISEMENT_SUCCEEDED: any = 'GET_ADVERTISEMENT_SUCCEEDED';
export const GET_ADVERTISEMENT_FAILED: any = 'GET_ADVERTISEMENT_FAILED';

export const ADD_ADVERTISEMENT: any = 'ADD_ADVERTISEMENT';
export const ADD_ADVERTISEMENT_SUCCEEDED: any = 'ADD_ADVERTISEMENT_SUCCEEDED';
export const ADD_ADVERTISEMENT_FAILED: any = 'ADD_ADVERTISEMENT_FAILED';

export const EDIT_ADVERTISEMENT: any = 'EDIT_ADVERTISEMENT';
export const EDIT_ADVERTISEMENT_SUCCEEDED: any = 'EDIT_ADVERTISEMENT_SUCCEEDED';
export const EDIT_ADVERTISEMENT_FAILED: any = 'EDIT_ADVERTISEMENT_FAILED';

export const DELETE_ADVERTISEMENT: any = 'DELETE_ADVERTISEMENT';
export const DELETE_ADVERTISEMENT_SUCCEEDED: any = 'DELETE_ADVERTISEMENT_SUCCEEDED';
export const DELETE_ADVERTISEMENT_FAILED: any = 'DELETE_ADVERTISEMENT_FAILED';

// Advertisement View
export const GET_ADVERTISEMENT_FOR_PAGE: any = 'GET_ADVERTISEMENT_FOR_PAGE';
export const GET_ADVERTISEMENT_FOR_PAGE_SUCCEEDED: any =
  'GET_ADVERTISEMENT_FOR_PAGE_SUCCEEDED';

export const GET_ADVERTISEMENT_FOR_PAGE_FAILED: any =
  'GET_ADVERTISEMENT_FOR_PAGE_FAILED';

// Notifications
export const SEND_NOTIFICATION_FOR_TUTOR_REVIEW: any =
  'SEND_NOTIFICATION_FOR_TUTOR_REVIEW';
export const SEND_NOTIFICATION_FOR_TUTOR_REVIEW_SUCCEEDED: any =
  'SEND_NOTIFICATION_FOR_TUTOR_REVIEW_SUCCEEDED';
export const SEND_NOTIFICATION_FOR_TUTOR_REVIEW_FAILED: any =
  'SEND_NOTIFICATION_FOR_TUTOR_REVIEW_FAILED';

export const GET_NOTIFICATIONS: any = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCEEDED: any = 'GET_NOTIFICATIONS_SUCCEEDED';
export const GET_NOTIFICATIONS_FAILED: any = 'GET_NOTIFICATIONS_FAILED';

export const GET_NOTIFICATIONS_BY_PAGE: any = 'GET_NOTIFICATIONS_BY_PAGE';
export const GET_NOTIFICATIONS_BY_PAGE_SUCCEEDED: any =
  'GET_NOTIFICATIONS_BY_PAGE_SUCCEEDED';

export const GET_NOTIFICATIONS_BY_PAGE_FAILED: any =
  'GET_NOTIFICATIONS_BY_PAGE_FAILED';

export const READ_SINGLE_NOTIFICATION: any = 'READ_SINGLE_NOTIFICATION';
export const READ_SINGLE_NOTIFICATION_SUCCEEDED: any =
  'READ_SINGLE_NOTIFICATION_SUCCEEDED';

export const READ_SINGLE_NOTIFICATION_FAILED: any =
  'READ_SINGLE_NOTIFICATION_FAILED';

export const READ_ALL_NOTIFICATIONS: any = 'READ_ALL_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS_SUCCEEDED: any =
  'READ_ALL_NOTIFICATIONS_SUCCEEDED';

export const READ_ALL_NOTIFICATIONS_FAILED: any = 'READ_ALL_NOTIFICATIONS_FAILED';

export const CLEAR_NOTIFICATION_STATE: any = 'CLEAR_NOTIFICATION_STATE';

export const SET_LIVE_NOTIFICATION_MESSAGE: any = 'SET_LIVE_NOTIFICATION_MESSAGE';
export const REMOVE_LIVE_NOTIFICATION_MESSAGE: any =
  'REMOVE_LIVE_NOTIFICATION_MESSAGE';

//Admin Dashboard
export const GET_ADMIN_DASHBOARD: any = 'GET_ADMIN_DASHBOARD';
export const GET_ADMIN_DASHBOARD_SUCCEEDED: any = 'GET_ADMIN_DASHBOARD_SUCCEEDED';
export const GET_ADMIN_DASHBOARD_FAILED: any = 'GET_ADMIN_DASHBOARD_FAILED';

//USER PROFILE
//basic
export const GET_USER_BASIC_INFO: any = 'GET_USER_BASIC_INFO';
export const GET_USER_BASIC_INFO_SUCCEEDED: any = 'GET_USER_BASIC_INFO_SUCCEEDED';
export const GET_USER_BASIC_INFO_FAILED: any = 'GET_USER_BASIC_INFO_FAILED';

export const EDIT_USER_BASIC_INFO: any = 'EDIT_USER_BASIC_INFO';
export const EDIT_USER_BASIC_INFO_SUCCEEDED: any = 'EDIT_USER_BASIC_INFO_SUCCEEDED';
export const EDIT_USER_BASIC_INFO_FAILED: any = 'EDIT_USER_BASIC_INFO_FAILED';

//social
export const GET_USER_SOCIAL_INFO: any = 'GET_USER_SOCIAL_INFO';
export const GET_USER_SOCIAL_INFO_SUCCEEDED: any = 'GET_USER_SOCIAL_INFO_SUCCEEDED';
export const GET_USER_SOCIAL_INFO_FAILED: any = 'GET_USER_SOCIAL_INFO_FAILED';

export const EDIT_USER_SOCIAL_INFO: any = 'EDIT_USER_SOCIAL_INFO';
export const EDIT_USER_SOCIAL_INFO_SUCCEEDED: any =
  'EDIT_USER_SOCIAL_INFO_SUCCEEDED';
export const EDIT_USER_SOCIAL_INFO_FAILED: any = 'EDIT_USER_SOCIAL_INFO_FAILED';

//education
export const GET_USER_EDUCATION_INFO: any = 'GET_USER_EDUCATION_INFO';
export const GET_USER_EDUCATION_INFO_SUCCEEDED: any =
  'GET_USER_EDUCATION_INFO_SUCCEEDED';
export const GET_USER_EDUCATION_INFO_FAILED: any = 'GET_USER_EDUCATION_INFO_FAILED';
export const ADD_USER_EDUCATION_INFO: any = 'ADD_USER_EDUCATION_INFO';
export const ADD_USER_EDUCATION_INFO_SUCCEEDED: any =
  'ADD_USER_EDUCATION_INFO_SUCCEEDED';
export const ADD_USER_EDUCATION_INFO_FAILED: any = 'ADD_USER_EDUCATION_INFO_FAILED';

export const EDIT_USER_EDUCATION_INFO: any = 'EDIT_USER_EDUCATION_INFO';
export const EDIT_USER_EDUCATION_INFO_SUCCEEDED: any =
  'EDIT_USER_EDUCATION_INFO_SUCCEEDED';
export const EDIT_USER_EDUCATION_INFO_FAILED: any =
  'EDIT_USER_EDUCATION_INFO_FAILED';

export const DELETE_USER_EDUCATION_INFO: any = 'DELETE_USER_EDUCATION_INFO';
export const DELETE_USER_EDUCATION_INFO_SUCCEEDED: any =
  'DELETE_USER_EDUCATION_INFO_SUCCEEDED';
export const DELETE_USER_EDUCATION_INFO_FAILED: any =
  'DELETE_USER_EDUCATION_INFO_FAILED';

//experience
export const GET_USER_EXPERIENCE_INFO: any = 'GET_USER_EXPERIENCE_INFO';
export const GET_USER_EXPERIENCE_INFO_SUCCEEDED: any =
  'GET_USER_EXPERIENCE_INFO_SUCCEEDED';
export const GET_USER_EXPERIENCE_INFO_FAILED: any =
  'GET_USER_EXPERIENCE_INFO_FAILED';

export const ADD_USER_EXPERIENCE_INFO: any = 'ADD_USER_EXPERIENCE_INFO';
export const ADD_USER_EXPERIENCE_INFO_SUCCEEDED: any =
  'ADD_USER_EXPERIENCE_INFO_SUCCEEDED';
export const ADD_USER_EXPERIENCE_INFO_FAILED: any =
  'ADD_USER_EXPERIENCE_INFO_FAILED';

export const EDIT_USER_EXPERIENCE_INFO: any = 'EDIT_USER_EXPERIENCE_INFO';
export const EDIT_USER_EXPERIENCE_INFO_SUCCEEDED: any =
  'EDIT_USER_EXPERIENCE_INFO_SUCCEEDED';
export const EDIT_USER_EXPERIENCE_INFO_FAILED: any =
  'EDIT_USER_EXPERIENCE_INFO_FAILED';

export const DELETE_USER_EXPERIENCE_INFO: any = 'DELETE_USER_EXPERIENCE_INFO';
export const DELETE_USER_EXPERIENCE_INFO_SUCCEEDED: any =
  'DELETE_USER_EXPERIENCE_INFO_SUCCEEDED';
export const DELETE_USER_EXPERIENCE_INFO_FAILED: any =
  'DELETE_USER_EXPERIENCE_INFO_FAILED';

export const SET_PROFILE_DATA: any = 'SET_PROFILE_DATA';
export const SET_PROFILE_EDIT_DATA: any = 'SET_PROFILE_EDIT_DATA';
export const SET_PROFILE_ERROR_DATA: any = 'SET_PROFILE_ERROR_DATA';

export const SET_CURRENT_TIME: any = 'SET_CURRENT_TIME';
export const TOGGLE_VIDEO_PAUSE: any = 'TOGGLE_VIDEO_PAUSE';
export const VIDEO_ENDED: any = 'VIDEO_ENDED';

export const QUIZ_ENDED: any = 'QUIZ_ENDED';

export const SET_TIME_FOR_VIDEO_TO_PLAY: any = 'SET_TIME_FOR_VIDEO_TO_PLAY';
export const ADD_VIDEO_DATA: any = 'ADD_VIDEO_DATA';
export const SET_VIDEO_DATA: any = 'SET_VIDEO_DATA';

export const SEARCH_VIDEO: any = 'SEARCH_VIDEO';
export const SEARCH_VIDEO_SUCCEEDED: any = 'SEARCH_VIDEO_SUCCEEDED';
export const SEARCH_VIDEO_FAILED: any = 'SEARCH_VIDEO_FAILED';

//course doubts
export const GET_COURSE_DOUBTS: any = 'GET_COURSE_DOUBTS';
export const GET_COURSE_DOUBTS_SUCCEEDED: any = 'GET_COURSE_DOUBTS_SUCCEEDED';
export const GET_COURSE_DOUBTS_FAILED: any = 'GET_COURSE_DOUBTS_FAILED';

//course doubt comments
export const GET_COURSE_DOUBT_COMMENTS: any = 'GET_COURSE_DOUBT_COMMENTS';
export const GET_COURSE_DOUBT_COMMENTS_SUCCEEDED: any =
  'GET_COURSE_DOUBT_COMMENTS_SUCCEEDED';
export const GET_COURSE_DOUBT_COMMENTS_FAILED: any =
  'GET_COURSE_DOUBT_COMMENTS_FAILED';

export const ADD_COURSE_DOUBT_COMMENTS: any = 'ADD_COURSE_DOUBT_COMMENTS';
export const ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED: any =
  'ADD_COURSE_DOUBT_COMMENTS_SUCCEEDED';
export const ADD_COURSE_DOUBT_COMMENTS_FAILED: any =
  'ADD_COURSE_DOUBT_COMMENTS_FAILED';

export const EDIT_COURSE_DOUBT_COMMENTS: any = 'EDIT_COURSE_DOUBT_COMMENTS';
export const EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED: any =
  'EDIT_COURSE_DOUBT_COMMENTS_SUCCEEDED';
export const EDIT_COURSE_DOUBT_COMMENTS_FAILED: any =
  'EDIT_COURSE_DOUBT_COMMENTS_FAILED';

export const DELETE_COURSE_DOUBT_COMMENTS: any = 'DELETE_COURSE_DOUBT_COMMENTS';
export const DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED: any =
  'DELETE_COURSE_DOUBT_COMMENTS_SUCCEEDED';
export const DELETE_COURSE_DOUBT_COMMENTS_FAILED: any =
  'DELETE_COURSE_DOUBT_COMMENTS_FAILED';

export const SET_DOUBT_EDIT_DATA: any = 'SET_DOUBT_EDIT_DATA';

//course clients
export const GET_COURSE_CLIENTS: any = 'GET_COURSE_CLIENTS';
export const GET_COURSE_CLIENTS_SUCCEEDED: any = 'GET_COURSE_CLIENTS_SUCCEEDED';
export const GET_COURSE_CLIENTS_FAILED: any = 'GET_COURSE_CLIENTS_FAILED';

export const ADD_COURSE_CLIENTS: any = 'ADD_COURSE_CLIENTS';
export const ADD_COURSE_CLIENTS_SUCCEEDED: any = 'ADD_COURSE_CLIENTS_SUCCEEDED';
export const ADD_COURSE_CLIENTS_FAILED: any = 'ADD_COURSE_CLIENTS_FAILED';

export const EDIT_COURSE_CLIENTS: any = 'EDIT_COURSE_CLIENTS';
export const EDIT_COURSE_CLIENTS_SUCCEEDED: any = 'EDIT_COURSE_CLIENTS_SUCCEEDED';
export const EDIT_COURSE_CLIENTS_FAILED: any = 'EDIT_COURSE_CLIENTS_FAILED';

//course free-trial users
export const GET_COURSE_FREE_TRIALS: any = 'GET_COURSE_FREE_TRIALS';
export const GET_COURSE_FREE_TRIALS_SUCCEEDED: any =
  'GET_COURSE_FREE_TRIALS_SUCCEEDED';
export const GET_COURSE_FREE_TRIALS_FAILED: any = 'GET_COURSE_FREE_TRIALS_FAILED';

//course feedbacks
export const GET_COURSE_FEEDBACKS: any = 'GET_COURSE_FEEDBACKS';
export const GET_COURSE_FEEDBACKS_SUCCEEDED: any = 'GET_COURSE_FEEDBACKS_SUCCEEDED';
export const GET_COURSE_FEEDBACKS_FAILED: any = 'GET_COURSE_FEEDBACKS_FAILED';

export const GET_COURSE_FEEDBACK_BY_ID: any = 'GET_COURSE_FEEDBACK_BY_ID';
export const GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED: any =
  'GET_COURSE_FEEDBACK_BY_ID_SUCCEEDED';
export const GET_COURSE_FEEDBACK_BY_ID_FAILED: any =
  'GET_COURSE_FEEDBACK_BY_ID_FAILED';

// Room For Instructors

// Room
export const GET_ROOM_INSTRUCTOR: any = 'GET_ROOM_INSTRUCTOR';
export const GET_ROOM_INSTRUCTOR_SUCCEEDED: any = 'GET_ROOM_INSTRUCTOR_SUCCEEDED';
export const GET_ROOM_INSTRUCTOR_FAILED: any = 'GET_ROOM_INSTRUCTOR_FAILED';

export const GET_ROOM_INSTRUCTOR_BY_SLUG: any = 'GET_ROOM_INSTRUCTOR_BY_SLUG';
export const GET_ROOM_INSTRUCTOR_BY_SLUG_SUCCEEDED: any =
  'GET_ROOM_INSTRUCTOR_BY_SLUG_SUCCEEDED';
export const GET_ROOM_INSTRUCTOR_BY_SLUG_FAILED: any =
  'GET_ROOM_INSTRUCTOR_BY_SLUG_FAILED';

export const ADD_ROOM_INSTRUCTOR: any = 'ADD_ROOM_INSTRUCTOR';
export const ADD_ROOM_INSTRUCTOR_SUCCEEDED: any = 'ADD_ROOM_INSTRUCTOR_SUCCEEDED';
export const ADD_ROOM_INSTRUCTOR_FAILED: any = 'ADD_ROOM_INSTRUCTOR_FAILED';

export const EDIT_ROOM_INSTRUCTOR: any = 'EDIT_ROOM_INSTRUCTOR';
export const EDIT_ROOM_INSTRUCTOR_SUCCEEDED: any = 'EDIT_ROOM_INSTRUCTOR_SUCCEEDED';
export const EDIT_ROOM_INSTRUCTOR_FAILED: any = 'EDIT_ROOM_INSTRUCTOR_FAILED';

export const DELETE_ROOM_INSTRUCTOR: any = 'DELETE_ROOM_INSTRUCTOR';
export const DELETE_ROOM_INSTRUCTOR_SUCCEEDED: any =
  'DELETE_ROOM_INSTRUCTOR_SUCCEEDED';
export const DELETE_ROOM_INSTRUCTOR_FAILED: any = 'DELETE_ROOM_INSTRUCTOR_FAILED';

// Announcement
export const GET_ANNOUNCEMENT_INSTRUCTOR: any = 'GET_ANNOUNCEMENT_INSTRUCTOR';
export const GET_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED: any =
  'GET_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED';
export const GET_ANNOUNCEMENT_INSTRUCTOR_FAILED: any =
  'GET_ANNOUNCEMENT_INSTRUCTOR_FAILED';

export const ADD_ANNOUNCEMENT_INSTRUCTOR: any = 'ADD_ANNOUNCEMENT_INSTRUCTOR';
export const ADD_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED: any =
  'ADD_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED';
export const ADD_ANNOUNCEMENT_INSTRUCTOR_FAILED: any =
  'ADD_ANNOUNCEMENT_INSTRUCTOR_FAILED';

export const EDIT_ANNOUNCEMENT_INSTRUCTOR: any = 'EDIT_ANNOUNCEMENT_INSTRUCTOR';
export const EDIT_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED: any =
  'EDIT_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED';
export const EDIT_ANNOUNCEMENT_INSTRUCTOR_FAILED: any =
  'EDIT_ANNOUNCEMENT_INSTRUCTOR_FAILED';

export const DELETE_ANNOUNCEMENT_INSTRUCTOR: any = 'DELETE_ANNOUNCEMENT_INSTRUCTOR';
export const DELETE_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED: any =
  'DELETE_ANNOUNCEMENT_INSTRUCTOR_SUCCEEDED';
export const DELETE_ANNOUNCEMENT_INSTRUCTOR_FAILED: any =
  'DELETE_ANNOUNCEMENT_INSTRUCTOR_FAILED';

// Room Frontend

export const GET_ROOM: any = 'GET_ROOM';
export const GET_ROOM_SUCCEEDED: any = 'GET_ROOM_SUCCEEDED';
export const GET_ROOM_FAILED: any = 'GET_ROOM_FAILED';

export const GET_ROOM_DETAIL: any = 'GET_ROOM_DETAIL';
export const GET_ROOM_DETAIL_SUCCEEDED: any = 'GET_ROOM_DETAIL_SUCCEEDED';
export const GET_ROOM_DETAIL_FAILED: any = 'GET_ROOM_DETAIL_FAILED';

// Announcement
export const GET_ANNOUNCEMENT: any = 'GET_ANNOUNCEMENT';
export const GET_ANNOUNCEMENT_SUCCEEDED: any = 'GET_ANNOUNCEMENT_SUCCEEDED';
export const GET_ANNOUNCEMENT_FAILED: any = 'GET_ANNOUNCEMENT_FAILED';

// Room Meetings For Instructors

// Room Meetings
export const GET_ROOM_MEETINGS_INSTRUCTOR: any = 'GET_ROOM_MEETINGS_INSTRUCTOR';
export const GET_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED: any =
  'GET_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED';
export const GET_ROOM_MEETINGS_INSTRUCTOR_FAILED: any =
  'GET_ROOM_MEETINGS_INSTRUCTOR_FAILED';

export const ADD_ROOM_MEETINGS_INSTRUCTOR: any = 'ADD_ROOM_MEETINGS_INSTRUCTOR';
export const ADD_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED: any =
  'ADD_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED';
export const ADD_ROOM_MEETINGS_INSTRUCTOR_FAILED: any =
  'ADD_ROOM_MEETINGS_INSTRUCTOR_FAILED';

export const EDIT_ROOM_MEETINGS_INSTRUCTOR: any = 'EDIT_ROOM_MEETINGS_INSTRUCTOR';
export const EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED: any =
  'EDIT_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED';
export const EDIT_ROOM_MEETINGS_INSTRUCTOR_FAILED: any =
  'EDIT_ROOM_MEETINGS_INSTRUCTOR_FAILED';

export const DELETE_ROOM_MEETINGS_INSTRUCTOR: any =
  'DELETE_ROOM_MEETINGS_INSTRUCTOR';
export const DELETE_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED: any =
  'DELETE_ROOM_MEETINGS_INSTRUCTOR_SUCCEEDED';
export const DELETE_ROOM_MEETINGS_INSTRUCTOR_FAILED: any =
  'DELETE_ROOM_MEETINGS_INSTRUCTOR_FAILED';

// Room Discussions
export const GET_ROOM_DISCUSSION: any = 'GET_ROOM_DISCUSSION';
export const GET_ROOM_DISCUSSION_SUCCEEDED: any = 'GET_ROOM_DISCUSSION_SUCCEEDED';
export const GET_ROOM_DISCUSSION_FAILED: any = 'GET_ROOM_DISCUSSION_FAILED';

export const GET_ROOM_DISCUSSION_BY_PAGE: any = 'GET_ROOM_DISCUSSION_BY_PAGE';
export const GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED: any =
  'GET_ROOM_DISCUSSION_BY_PAGE_SUCCEEDED';
export const GET_ROOM_DISCUSSION_BY_PAGE_FAILED: any =
  'GET_ROOM_DISCUSSION_BY_PAGE_FAILED';

export const ADD_ROOM_DISCUSSION: any = 'ADD_ROOM_DISCUSSION';
export const ADD_ROOM_DISCUSSION_SUCCEEDED: any = 'ADD_ROOM_DISCUSSION_SUCCEEDED';
export const ADD_ROOM_DISCUSSION_FAILED: any = 'ADD_ROOM_DISCUSSION_FAILED';

export const EDIT_ROOM_DISCUSSION: any = 'EDIT_ROOM_DISCUSSION';
export const EDIT_ROOM_DISCUSSION_SUCCEEDED: any = 'EDIT_ROOM_DISCUSSION_SUCCEEDED';
export const EDIT_ROOM_DISCUSSION_FAILED: any = 'EDIT_ROOM_DISCUSSION_FAILED';

export const DELETE_ROOM_DISCUSSION: any = 'DELETE_ROOM_DISCUSSION';
export const DELETE_ROOM_DISCUSSION_SUCCEEDED: any =
  'DELETE_ROOM_DISCUSSION_SUCCEEDED';
export const DELETE_ROOM_DISCUSSION_FAILED: any = 'DELETE_ROOM_DISCUSSION_FAILED';

export const ROOM_DISCUSSION_LIKE: any = 'ROOM_DISCUSSION_LIKE';
export const ROOM_DISCUSSION_LIKE_SUCCEEDED: any = 'ROOM_DISCUSSION_LIKE_SUCCEEDED';
export const ROOM_DISCUSSION_LIKE_FAILED: any = 'ROOM_DISCUSSION_LIKE_FAILED';

// GET DISCUSSION REACTION LIST
export const GET_DISCUSSION_REACTION_LIST: any = 'GET_DISCUSSION_REACTION_LIST';
export const GET_DISCUSSION_REACTION_LIST_SUCCEEDED: any =
  'GET_DISCUSSION_REACTION_LIST_SUCCEEDED';
export const GET_DISCUSSION_REACTION_LIST_FAILED: any =
  'GET_DISCUSSION_REACTION_LIST_FAILED';

// GET COMMENT REACTION LIST
export const GET_DISCUSSION_COMMENT_REACTION_LIST: any =
  'GET_DISCUSSION_COMMENT_REACTION_LIST';
export const GET_DISCUSSION_COMMENT_REACTION_LIST_SUCCEEDED: any =
  'GET_DISCUSSION_COMMENT_REACTION_LIST_SUCCEEDED';
export const GET_DISCUSSION_COMMENT_REACTION_LIST_FAILED: any =
  'GET_DISCUSSION_COMMENT_REACTION_LIST_FAILED';

// GET REPLIES REACTION LIST
export const GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST: any =
  'GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST';
export const GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_SUCCEEDED: any =
  'GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_SUCCEEDED';
export const GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_FAILED: any =
  'GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST_FAILED';

export const REMOVE_DISSCUSSION_COMMENT_OBJECT: any =
  'REMOVE_DISSCUSSION_COMMENT_OBJECT';

// Poll Doubts
export const VOTE_ROOM_DISCUSSION_POLL: any = 'VOTE_ROOM_DISCUSSION_POLL';
export const VOTE_ROOM_DISCUSSION_POLL_SUCCEEDED: any =
  'VOTE_ROOM_DISCUSSION_POLL_SUCCEEDED';
export const VOTE_ROOM_DISCUSSION_POLL_FAILED: any =
  'VOTE_ROOM_DISCUSSION_POLL_FAILED';

// Room Discussion Comments Reply
export const GET_ROOM_DISCUSSION_COMMENT_REPLIES: any =
  'GET_ROOM_DISCUSSION_COMMENT_REPLIES';
export const GET_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED: any =
  'GET_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED';
export const GET_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED: any =
  'GET_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED';

export const ADD_ROOM_DISCUSSION_COMMENT_REPLIES: any =
  'ADD_ROOM_DISCUSSION_COMMENT_REPLIES';
export const ADD_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED: any =
  'ADD_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED';
export const ADD_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED: any =
  'ADD_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED';

export const LIKE_ROOM_DISCUSSION_COMMENT_REPLIES: any =
  'LIKE_ROOM_DISCUSSION_COMMENT_REPLIES';
export const LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED: any =
  'LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED';
export const LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED: any =
  'LIKE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED';

export const DELETE_ROOM_DISCUSSION_COMMENT_REPLIES: any =
  'DELETE_ROOM_DISCUSSION_COMMENT_REPLIES';
export const DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED: any =
  'DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED';
export const DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED: any =
  'DELETE_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED';

export const EDIT_ROOM_DISCUSSION_COMMENT_REPLIES: any =
  'EDIT_ROOM_DISCUSSION_COMMENT_REPLIES';
export const EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED: any =
  'EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_SUCCEEDED';
export const EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED: any =
  'EDIT_ROOM_DISCUSSION_COMMENT_REPLIES_FAILED';

export const INITIAL_ROOM_DISCUSSION_COMMENT_REPLIES_QUERY =
  'INITIAL_ROOM_DISCUSSION_COMMENT_REPLIES_QUERY';

export const CLEAR_COMMENT_REPLY_DATA = 'CLEAR_COMMENT_REPLY_DATA';

// Room Discussion Comment
export const GET_ROOM_DISCUSSION_COMMENT: any = 'GET_ROOM_DISCUSSION_COMMENT';
export const GET_ROOM_DISCUSSION_COMMENT_SUCCEEDED: any =
  'GET_ROOM_DISCUSSION_COMMENT_SUCCEEDED';
export const GET_ROOM_DISCUSSION_COMMENT_FAILED: any =
  'GET_ROOM_DISCUSSION_COMMENT_FAILED';

export const GET_ROOM_DISCUSSION_COMMENT_BY_PAGE: any =
  'GET_ROOM_DISCUSSION_COMMENT_BY_PAGE';
export const GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_SUCCEEDED: any =
  'GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_SUCCEEDED';
export const GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_FAILED: any =
  'GET_ROOM_DISCUSSION_COMMENT_BY_PAGE_FAILED';

export const ADD_ROOM_DISCUSSION_COMMENT: any = 'ADD_ROOM_DISCUSSION_COMMENT';
export const ADD_ROOM_DISCUSSION_COMMENT_SUCCEEDED: any =
  'ADD_ROOM_DISCUSSION_COMMENT_SUCCEEDED';
export const ADD_ROOM_DISCUSSION_COMMENT_FAILED: any =
  'ADD_ROOM_DISCUSSION_COMMENT_FAILED';

export const EDIT_ROOM_DISCUSSION_COMMENT: any = 'EDIT_ROOM_DISCUSSION_COMMENT';
export const EDIT_ROOM_DISCUSSION_COMMENT_SUCCEEDED: any =
  'EDIT_ROOM_DISCUSSION_COMMENT_SUCCEEDED';
export const EDIT_ROOM_DISCUSSION_COMMENT_FAILED: any =
  'EDIT_ROOM_DISCUSSION_COMMENT_FAILED';

export const DELETE_ROOM_DISCUSSION_COMMENT: any = 'DELETE_ROOM_DISCUSSION_COMMENT';
export const DELETE_ROOM_DISCUSSION_COMMENT_SUCCEEDED: any =
  'DELETE_ROOM_DISCUSSION_COMMENT_SUCCEEDED';
export const DELETE_ROOM_DISCUSSION_COMMENT_FAILED: any =
  'DELETE_ROOM_DISCUSSION_COMMENT_FAILED';

export const ROOM_DISCUSSION_LIKE_COMMENT: any = 'ROOM_DISCUSSION_LIKE_COMMENT';
export const ROOM_DISCUSSION_LIKE_COMMENT_SUCCEEDED: any =
  'ROOM_DISCUSSION_LIKE_COMMENT_SUCCEEDED';
export const ROOM_DISCUSSION_LIKE_COMMENT_FAILED: any =
  'ROOM_DISCUSSION_LIKE_COMMENT_FAILED';

export const ROOM_DISCUSSION_REPORT: any = 'ROOM_DISCUSSION_REPORT';
export const ROOM_DISCUSSION_REPORT_SUCCEEDED: any =
  'ROOM_DISCUSSION_REPORT_SUCCEEDED';
export const ROOM_DISCUSSION_REPORT_FAILED: any = 'ROOM_DISCUSSION_REPORT_FAILED';

export const CLEAR_COMMENT_DISCCUSION_DATA = 'CLEAR_COMMENT_DISCCUSION_DATA';
export const SET_ROOM_DISCUSSION_COMMENT_QUERY = 'SET_ROOM_DISCUSSION_COMMENT_QUERY';
export const INITIAL_ROOM_DISCUSSION_COMMENT_QUERY =
  'INITIAL_ROOM_DISCUSSION_COMMENT_QUERY';

// Room Meetings Frontend

export const GET_ROOM_MEETINGS: any = 'GET_ROOM_MEETINGS';
export const GET_ROOM_MEETINGS_SUCCEEDED: any = 'GET_ROOM_MEETINGS_SUCCEEDED';
export const GET_ROOM_MEETINGS_FAILED: any = 'GET_ROOM_MEETINGS_FAILED';

export const GET_ROOM_MEETINGS_BY_SLUG: any = 'GET_ROOM_MEETINGS_BY_SLUG';
export const GET_ROOM_MEETINGS_BY_SLUG_SUCCEEDED: any =
  'GET_ROOM_MEETINGS_BY_SLUG_SUCCEEDED';
export const GET_ROOM_MEETINGS_BY_SLUG_FAILED: any =
  'GET_ROOM_MEETINGS_BY_SLUG_FAILED';

// Meetings Video
export const GET_MEETING_VIDEO: any = 'GET_MEETING_VIDEO';
export const GET_MEETING_VIDEO_SUCCEEDED: any = 'GET_MEETING_VIDEO_SUCCEEDED';
export const GET_MEETING_VIDEO_FAILED: any = 'GET_MEETING_VIDEO_FAILED';

export const ADD_MEETING_VIDEO: any = 'ADD_MEETING_VIDEO';
export const ADD_MEETING_VIDEO_SUCCEEDED: any = 'ADD_MEETING_VIDEO_SUCCEEDED';
export const ADD_MEETING_VIDEO_FAILED: any = 'ADD_MEETING_VIDEO_FAILED';

// Room Members
export const GET_ROOM_MEMBERS: any = 'GET_ROOM_MEMBERS';
export const GET_ROOM_MEMBERS_SUCCEEDED: any = 'GET_ROOM_MEMBERS_SUCCEEDED';
export const GET_ROOM_MEMBERS_FAILED: any = 'GET_ROOM_MEMBERS_FAILED';

export const UPDATE_ROOM_MEMBER: any = 'UPDATE_ROOM_MEMBER';
export const UPDATE_ROOM_MEMBER_SUCCEEDED: any = 'UPDATE_ROOM_MEMBER_SUCCEEDED';
export const UPDATE_ROOM_MEMBER_FAILED: any = 'UPDATE_ROOM_MEMBER_FAILED';

export const GET_ROOM_MEMBERS_BY_PAGE: any = 'GET_ROOM_MEMBERS_BY_PAGE';
export const GET_ROOM_MEMBERS_BY_PAGE_SUCCEEDED: any =
  'GET_ROOM_MEMBERS_BY_PAGE_SUCCEEDED';
export const GET_ROOM_MEMBERS_BY_PAGE_FAILED: any =
  'GET_ROOM_MEMBERS_BY_PAGE_FAILED';

export const RESPOND_TO_INVITATION_REQUEST: any = 'RESPOND_TO_INVITATION_REQUEST';
export const RESPOND_TO_INVITATION_REQUEST_SUCCEEDED: any =
  'RESPOND_TO_INVITATION_REQUEST_SUCCEEDED';
export const RESPOND_TO_INVITATION_REQUEST_FAILED: any =
  'RESPOND_TO_INVITATION_REQUEST_FAILED';

export const REJECT_TO_INVITATION_REQUEST: any = 'REJECT_TO_INVITATION_REQUEST';
export const REJECT_TO_INVITATION_REQUEST_SUCCEEDED: any =
  'REJECT_TO_INVITATION_REQUEST_SUCCEEDED';
export const REJECT_TO_INVITATION_REQUEST_FAILED: any =
  'REJECT_TO_INVITATION_REQUEST_FAILED';

export const MIGRATE_COURSE_ENROLLED_USERS: any = 'MIGRATE_COURSE_ENROLLED_USERS';
export const MIGRATE_COURSE_ENROLLED_USERS_SUCCEEDED: any =
  'MIGRATE_COURSE_ENROLLED_USERS_SUCCEEDED';
export const MIGRATE_COURSE_ENROLLED_USERS_FAILED: any =
  'MIGRATE_COURSE_ENROLLED_USERS_FAILED';

export const SET_ROOM_DATA: any = 'SET_ROOM_DATA';
export const SET_ROOM_EDIT_DATA: any = 'SET_ROOM_EDIT_DATA';
export const SET_ROOM_MEMBER_ACTIVE_TAB: any = 'SET_ROOM_MEMBER_ACTIVE_TAB';
export const CLEAR_ROOM_QUERY: any = 'CLEAR_ROOM_QUERY';
export const SET_ROOM_QUERY_DATA = 'SET_ROOM_QUERY_DATA';
export const CLEAR_ROOM_DATA = 'CLEAR_ROOM_DATA';
export const CLEAR_ROOM_DATA_TO_INITIAL = 'CLEAR_ROOM_DATA_TO_INITIAL';
export const SET_ROOM_ERROR_DATA = 'SET_ROOM_ERROR_DATA';

// Remove Room Members

export const REMOVE_ROOM_MEMBERS: any = 'REMOVE_ROOM_MEMBERS';
export const REMOVE_ROOM_MEMBERS_SUCCEEDED: any = 'REMOVE_ROOM_MEMBERS_SUCCEEDED';
export const REMOVE_ROOM_MEMBERS_FAILED: any = 'REMOVE_ROOM_MEMBERS_FAILED';

export const RESPOND_GOING_TO_MEETING: any = 'RESPOND_GOING_TO_MEETING';
export const RESPOND_GOING_TO_MEETING_SUCCEEDED: any =
  'RESPOND_GOING_TO_MEETING_SUCCEEDED';
export const RESPOND_GOING_TO_MEETING_FAILED: any =
  'RESPOND_GOING_TO_MEETING_FAILED';

export const JOIN_ROOM_REQUEST: any = 'JOIN_ROOM_REQUEST';
export const JOIN_ROOM_SUCCEEDED: any = 'JOIN_ROOM_SUCCEEDED';
export const JOIN_ROOM_FAILED: any = 'JOIN_ROOM_FAILED';

export const LEAVE_ROOM_REQUEST: any = 'LEAVE_ROOM_REQUEST';
export const LEAVE_ROOM_SUCCEEDED: any = 'LEAVE_ROOM_SUCCEEDED';
export const LEAVE_ROOM_FAILED: any = 'LEAVE_ROOM_FAILED';

export const INVITE_MEMBER_REQUEST: any = 'INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCEEDED: any = 'INVITE_MEMBER_SUCCEEDED';
export const INVITE_MEMBER_FAILED: any = 'INVITE_MEMBER_FAILED';

export const CLEAR_ROOM_ERROR: any = 'CLEAR_ROOM_ERROR';

// Live Class List
export const GET_LIVE_CLASS: any = 'GET_LIVE_CLASS';
export const GET_LIVE_CLASS_SUCCEEDED: any = 'GET_LIVE_CLASS_SUCCEEDED';
export const GET_LIVE_CLASS_FAILED: any = 'GET_LIVE_CLASS_FAILED';

//course live class
export const GET_COURSE_LIVE_CLASS: any = 'GET_COURSE_LIVE_CLASS';
export const GET_COURSE_LIVE_CLASS_SUCCEEDED: any =
  'GET_COURSE_LIVE_CLASS_SUCCEEDED';
export const GET_COURSE_LIVE_CLASS_FAILED: any = 'GET_COURSE_LIVE_CLASS_FAILED';

export const ADD_COURSE_LIVE_CLASS: any = 'ADD_COURSE_LIVE_CLASS';
export const ADD_COURSE_LIVE_CLASS_SUCCEEDED: any =
  'ADD_COURSE_LIVE_CLASS_SUCCEEDED';
export const ADD_COURSE_LIVE_CLASS_FAILED: any = 'ADD_COURSE_LIVE_CLASS_FAILED';

export const EDIT_COURSE_LIVE_CLASS: any = 'EDIT_COURSE_LIVE_CLASS';
export const EDIT_COURSE_LIVE_CLASS_SUCCEEDED: any =
  'EDIT_COURSE_LIVE_CLASS_SUCCEEDED';
export const EDIT_COURSE_LIVE_CLASS_FAILED: any = 'EDIT_COURSE_LIVE_CLASS_FAILED';

export const DELETE_COURSE_LIVE_CLASS: any = 'DELETE_COURSE_LIVE_CLASS';
export const DELETE_COURSE_LIVE_CLASS_SUCCEEDED: any =
  'DELETE_COURSE_LIVE_CLASS_SUCCEEDED';
export const DELETE_COURSE_LIVE_CLASS_FAILED: any =
  'DELETE_COURSE_LIVE_CLASS_FAILED';

export const SUBMIT_MOCK_TEST: any = 'SUBMIT_MOCK_TEST';
export const SUBMIT_MOCK_TEST_SUCCEEDED: any = 'SUBMIT_MOCK_TEST_SUCCEEDED';
export const SUBMIT_MOCK_TEST_FAILED: any = 'SUBMIT_MOCK_TEST_FAILED';

export const CLEAR_MOCK_TEST_STATE: any = 'CLEAR_MOCK_TEST_STATE';
export const COUNT_DOWN_TIMER: any = 'COUNT_DOWN_TIMER';

export const UPDATE_MOCK_TEST: any = 'UPDATE_MOCK_TEST';
export const UPDATE_MOCK_TEST_SUCCEEDED: any = 'UPDATE_MOCK_TEST_SUCCEEDED';
export const UPDATE_MOCK_TEST_FAILED: any = 'UPDATE_MOCK_TEST_FAILED';

// Mock Test Time Finished
export const TEST_TIME_FINISHED: any = 'TEST_TIME_FINISHED';

// Mock Test for Exam
export const GET_MOCK_TEST_LIST: any = 'GET_MOCK_TEST_LIST';
export const GET_MOCK_TEST_LIST_SUCCEEDED: any = 'GET_MOCK_TEST_LIST_SUCCEEDED';
export const GET_MOCK_TEST_LIST_FAILED: any = 'GET_MOCK_TEST_LIST_FAILED';

export const GET_MOCK_TEST_LIST_BY_PAGE: any = 'GET_MOCK_TEST_LIST_BY_PAGE';
export const GET_MOCK_TEST_LIST_BY_PAGE_SUCCEEDED: any =
  'GET_MOCK_TEST_LIST_BY_PAGE_SUCCEEDED';
export const GET_MOCK_TEST_LIST_BY_PAGE_FAILED: any =
  'GET_MOCK_TEST_LIST_BY_PAGE_FAILED';

export const GET_MOCK_TEST_COURSES: any = 'GET_MOCK_TEST_COURSES';
export const GET_MOCK_TEST_COURSES_SUCCEEDED: any =
  'GET_MOCK_TEST_COURSES_SUCCEEDED';
export const GET_MOCK_TEST_COURSES_FAILED: any = 'GET_MOCK_TEST_COURSES';

export const GET_MOCK_TEST_COURSES_BY_PAGE: any = 'GET_MOCK_TEST_COURSES_BY_PAGE';
export const GET_MOCK_TEST_COURSES_BY_PAGE_SUCCEEDED: any =
  'GET_MOCK_TEST_COURSES_BY_PAGE_SUCCEEDED';
export const GET_MOCK_TEST_COURSES_BY_PAGE_FAILED: any =
  'GET_MOCK_TEST_COURSES_BY_PAGE_FAILED';

// Mock Test By Slug
export const GET_MOCK_TEST_BY_SLUG: any = 'GET_MOCK_TEST_BY_SLUG';
export const GET_MOCK_TEST_BY_SLUG_SUCCEEDED: any =
  'GET_MOCK_TEST_BY_SLUG_SUCCEEDED';
export const GET_MOCK_TEST_BY_SLUG_FAILED: any = 'GET_MOCK_TEST_BY_SLUG_FAILED';

// Mock Test List By Slug
export const GET_MOCK_TEST_LIST_BY_SLUG: any = 'GET_MOCK_TEST_LIST_BY_SLUG';
export const GET_MOCK_TEST_LIST_BY_SLUG_SUCCEEDED: any =
  'GET_MOCK_TEST_LIST_BY_SLUG_SUCCEEDED';
export const GET_MOCK_TEST_LIST_BY_SLUG_FAILED: any =
  'GET_MOCK_TEST_LIST_BY_SLUG_FAILED';

export const SET_MOCK_TEST_QUERY_DATA: any = 'SET_MOCK_TEST_QUERY_DATA';
export const CLEAR_MOCK_TEST_QUERY: any = 'CLEAR_MOCK_TEST_QUERY';
export const SET_MOCK_TEST_EDIT_DATA: any = 'SET_MOCK_TEST_EDIT_DATA';

// Mock Test for Exam
export const GET_MOCK_TEST: any = 'GET_MOCK_TEST';
export const GET_MOCK_TEST_SUCCEEDED: any = 'GET_MOCK_TEST_SUCCEEDED';
export const GET_MOCK_TEST_FAILED: any = 'GET_MOCK_TEST_FAILED';

// Mock Test for Exam
export const GET_MOCK_TEST_REPORT: any = 'GET_MOCK_TEST_REPORT';
export const GET_MOCK_TEST_REPORT_SUCCEEDED: any = 'GET_MOCK_TEST_REPORT_SUCCEEDED';
export const GET_MOCK_TEST_REPORT_FAILED: any = 'GET_MOCK_TEST_REPORT_FAILED';
// Mock Test Report For Admin and partner
export const GET_MOCK_TEST_REPORT_ADMIN_PARTNER: any =
  'GET_MOCK_TEST_REPORT_ADMIN_PARTNER';
export const GET_MOCK_TEST_REPORT_ADMIN_PARTNER_SUCCEEDED: any =
  'GET_MOCK_TEST_REPORT_ADMIN_PARTNER_SUCCEEDED';
export const GET_MOCK_TEST_REPORT_ADMIN_PARTNER_FAILED: any =
  'GET_MOCK_TEST_REPORT_ADMIN_PARTNER_FAILED';

// Mock Test Writing
export const SAVE_WRITING_MOCK_TEST_ANSWER: any = 'SAVE_WRITING_MOCK_TEST_ANSWER';
export const GET_WRITING_MOCK_TEST_ANSWER: any = 'GET_WRITING_MOCK_TEST_ANSWER';

//live class
export const SET_LIVE_DATA: any = 'SET_LIVE_DATA';

// Dropdowns
export const GET_USER_DROPDOWN: any = 'GET_USER_DROPDOWN';
export const GET_USER_DROPDOWN_SUCCEEDED: any = 'GET_USER_DROPDOWN_SUCCEEDED';
export const GET_USER_DROPDOWN_FAILED: any = 'GET_USER_DROPDOWN_FAILED';

export const GET_USER_DROPDOWN_CLIENT: any = 'GET_USER_DROPDOWN_CLIENT';
export const GET_USER_DROPDOWN_CLIENT_SUCCEEDED: any =
  'GET_USER_DROPDOWN_CLIENT_SUCCEEDED';
export const GET_USER_DROPDOWN_CLIENT_FAILED: any =
  'GET_USER_DROPDOWN_CLIENT_FAILED';

export const GET_COURSE_DROPDOWN: any = 'GET_COURSE_DROPDOWN';
export const GET_COURSE_DROPDOWN_SUCCEEDED: any = 'GET_COURSE_DROPDOWN_SUCCEEDED';
export const GET_COURSE_DROPDOWN_FAILED: any = 'GET_COURSE_DROPDOWN_FAILED';

export const GET_SUBJECT_CONTENT_DROPDOWN: any = 'GET_SUBJECT_CONTENT_DROPDOWN';
export const GET_SUBJECT_CONTENT_DROPDOWN_SUCCEEDED: any =
  'GET_SUBJECT_CONTENT_DROPDOWN_SUCCEEDED';
export const GET_SUBJECT_CONTENT_DROPDOWN_FAILED: any =
  'GET_SUBJECT_CONTENT_DROPDOWN_FAILED';

export const CLEAR_DROPDOWN_DATA: any = 'CLEAR_DROPDOWN_DATA';
export const SET_DROPDOWN_DATA: any = 'SET_DROPDOWN_DATA';
export const SET_DROPDOWN_EDIT_DATA: any = 'SET_DROPDOWN_EDIT_DATA';

// Payment

export const PAYMENT_REQUEST: any = 'PAYMENT_REQUEST';
export const PAYMENT_SUCCEEDED: any = 'PAYMENT_SUCCEEDED';
export const PAYMENT_FAILED: any = 'PAYMENT_FAILED';

export const PURCHASE_HISTORY_REQUEST: any = 'PURCHASE_HISTORY_REQUEST';
export const PURCHASE_HISTORY_SUCCEEDED: any = 'PURCHASE_HISTORY_SUCCEEDED';
export const PURCHASE_HISTORY_FAILED: any = 'PURCHASE_HISTORY_FAILED';

export const SET_ORDER_DATA: any = 'SET_ORDER_DATA';
export const SET_PAYMENT_EDIT_DATA: any = 'SET_PAYMENT_EDIT_DATA';
export const SET_PAYMENT_QUERY_DATA: any = 'SET_PAYMENT_QUERY_DATA';
export const CLEAR_PAYMENT_QUERY: any = 'CLEAR_PAYMENT_QUERY';
export const CLEAR_PAYMENT_DATA: any = 'CLEAR_PAYMENT_DATA';
export const CLEAR_SINGLE_ORDER_ITEM: any = 'CLEAR_SINGLE_ORDER_ITEM';
export const CLEAR_ALL_ORDER_ITEM: any = 'CLEAR_ALL_ORDER_ITEM';

// Finance

export const GET_COUPON: any = 'GET_COUPON';
export const GET_COUPON_SUCCEEDED: any = 'GET_COUPON_SUCCEEDED';
export const GET_COUPON_FAILED: any = 'GET_COUPON_FAILED';

export const ADD_COUPON: any = 'ADD_COUPON';
export const ADD_COUPON_SUCCEEDED: any = 'ADD_COUPON_SUCCEEDED';
export const ADD_COUPON_FAILED: any = 'ADD_COUPON_FAILED';

export const EDIT_COUPON: any = 'EDIT_COUPON';
export const EDIT_COUPON_SUCCEEDED: any = 'EDIT_COUPON_SUCCEEDED';
export const EDIT_COUPON_FAILED: any = 'EDIT_COUPON_FAILED';

export const DELETE_COUPON: any = 'DELETE_COUPON';
export const DELETE_COUPON_SUCCEEDED: any = 'DELETE_COUPON_SUCCEEDED';
export const DELETE_COUPON_FAILED: any = 'DELETE_COUPON_FAILED';

export const USE_FREE_VOUCHER: any = 'USE_FREE_VOUCHER';
export const USE_FREE_VOUCHER_SUCCEEDED: any = 'USE_FREE_VOUCHER_SUCCEEDED';
export const USE_FREE_VOUCHER_FAILED: any = 'USE_FREE_VOUCHER_FAILED';

export const SET_FINANCE_EDIT_DATA: any = 'SET_FINANCE_EDIT_DATA';
export const SET_FINANCE_ERROR_DATA: any = 'SET_FINANCE_ERROR_DATA';
export const SET_FINANCE_DATA: any = 'SET_FINANCE_DATA';
export const CLEAR_FINANCE_ERROR: any = 'CLEAR_FINANCE_ERROR';

export const GET_COUPON_BY_ID: any = 'GET_COUPON_BY_ID';
export const GET_COUPON_BY_ID_SUCCEEDED: any = 'GET_COUPON_BY_ID_SUCCEEDED';
export const GET_COUPON_BY_ID_FAILED: any = 'GET_COUPON_BY_ID_FAILED';

// Voucher

export const VOUCHER_VERIFY: any = 'VOUCHER_VERIFY';
export const VOUCHER_VERIFY_SUCCESS: any = 'VOUCHER_VERIFY_SUCCESS';
export const VOUCHER_VERIFY_FAILURE: any = 'VOUCHER_VERIFY_FAILURE';

export const ACTIVE_SUBJECT_ID: any = 'ACTIVE_SUBJECT_ID';

export const GET_TUTOR_TERMS_CONDITIONS: any = 'GET_TUTOR_TERMS_CONDITIONS';
export const GET_TUTOR_TERMS_CONDITIONS_SUCCEEDED: any =
  'GET_TUTOR_TERMS_CONDITIONS_SUCCEEDED';
export const GET_TUTOR_TERMS_CONDITIONS_FAILED: any =
  'GET_TUTOR_TERMS_CONDITIONS_FAILED';

export const MODIFY_HELPER_REDUCER: any = 'MODIFY_HELPER_REDUCER';
export const SET_HELPER_EDIT_DATA: any = 'SET_HELPER_EDIT_DATA';

// Room Assessment frontend
//question
export const GET_ASSESSMENT: any = 'GET_ASSESSMENT';
export const GET_ASSESSMENT_SUCCEEDED: any = 'GET_ASSESSMENT_SUCCEEDED';
export const GET_ASSESSMENT_FAILED: any = 'GET_ASSESSMENT_FAILED';

export const GET_ASSESSMENT_BY_ID: any = 'GET_ASSESSMENT_BY_ID';
export const GET_ASSESSMENT_BY_ID_SUCCEEDED: any = 'GET_ASSESSMENT_BY_ID_SUCCEEDED';
export const GET_ASSESSMENT_BY_ID_FAILED: any = 'GET_ASSESSMENT_BY_ID_FAILED';

export const ADD_ASSESSMENT: any = 'ADD_ASSESSMENT';
export const ADD_ASSESSMENT_SUCCEEDED: any = 'ADD_ASSESSMENT_SUCCEEDED';
export const ADD_ASSESSMENT_FAILED: any = 'ADD_ASSESSMENT_FAILED';

export const EDIT_ASSESSMENT: any = 'EDIT_ASSESSMENT';
export const EDIT_ASSESSMENT_SUCCEEDED: any = 'EDIT_ASSESSMENT_SUCCEEDED';
export const EDIT_ASSESSMENT_FAILED: any = 'EDIT_ASSESSMENT_FAILED';

export const DELETE_ASSESSMENT: any = 'DELETE_ASSESSMENT';
export const DELETE_ASSESSMENT_SUCCEEDED: any = 'DELETE_ASSESSMENT_SUCCEEDED';
export const DELETE_ASSESSMENT_FAILED: any = 'DELETE_ASSESSMENT_FAILED';
//answer
export const GET_ASSESSMENT_ANSWER: any = 'GET_ASSESSMENT_ANSWER';
export const GET_ASSESSMENT_ANSWER_SUCCEEDED: any =
  'GET_ASSESSMENT_ANSWER_SUCCEEDED';
export const GET_ASSESSMENT_ANSWER_FAILED: any = 'GET_ASSESSMENT_ANSWER_FAILED';

export const GET_ASSESSMENT_ANSWER_BY_ID: any = 'GET_ASSESSMENT_ANSWER_BY_ID';
export const GET_ASSESSMENT_ANSWER_BY_ID_SUCCEEDED: any =
  'GET_ASSESSMENT_ANSWER_BY_ID_SUCCEEDED';
export const GET_ASSESSMENT_ANSWER_BY_ID_FAILED: any =
  'GET_ASSESSMENT_ANSWER_BY_ID_FAILED';

export const ADD_ASSESSMENT_ANSWER: any = 'ADD_ASSESSMENT_ANSWER';
export const ADD_ASSESSMENT_ANSWER_SUCCEEDED: any =
  'ADD_ASSESSMENT_ANSWER_SUCCEEDED';
export const ADD_ASSESSMENT_ANSWER_FAILED: any = 'ADD_ASSESSMENT_ANSWER_FAILED';

export const EDIT_ASSESSMENT_ANSWER: any = 'EDIT_ASSESSMENT_ANSWER';
export const EDIT_ASSESSMENT_ANSWER_SUCCEEDED: any =
  'EDIT_ASSESSMENT_ANSWER_SUCCEEDED';
export const EDIT_ASSESSMENT_ANSWER_FAILED: any = 'EDIT_ASSESSMENT_ANSWER_FAILED';

export const DELETE_ASSESSMENT_ANSWER: any = 'DELETE_ASSESSMENT_ANSWER';
export const DELETE_ASSESSMENT_ANSWER_SUCCEEDED: any =
  'DELETE_ASSESSMENT_ANSWER_SUCCEEDED';
export const DELETE_ASSESSMENT_ANSWER_FAILED: any =
  'DELETE_ASSESSMENT_ANSWER_FAILED';

// Question comment Assessment
export const GET_ASSESSMENT_QUESTION_COMMENT: any =
  'GET_ASSESSMENT_QUESTION_COMMENT';
export const GET_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED: any =
  'GET_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED';
export const GET_ASSESSMENT_QUESTION_COMMENT_FAILED: any =
  'GET_ASSESSMENT_QUESTION_COMMENT_FAILED';

export const GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE: any =
  'GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE';
export const GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_SUCCEEDED: any =
  'GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_SUCCEEDED';
export const GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_FAILED: any =
  'GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE_FAILED';

export const ADD_ASSESSMENT_QUESTION_COMMENT: any =
  'ADD_ASSESSMENT_QUESTION_COMMENT';
export const ADD_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED: any =
  'ADD_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED';
export const ADD_ASSESSMENT_QUESTION_COMMENT_FAILED: any =
  'ADD_ASSESSMENT_QUESTION_COMMENT_FAILED';

export const EDIT_ASSESSMENT_QUESTION_COMMENT: any =
  'EDIT_ASSESSMENT_QUESTION_COMMENT';
export const EDIT_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED: any =
  'EDIT_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED';
export const EDIT_ASSESSMENT_QUESTION_COMMENT_FAILED: any =
  'EDIT_ASSESSMENT_QUESTION_COMMENT_FAILED';

export const DELETE_ASSESSMENT_QUESTION_COMMENT: any =
  'DELETE_ASSESSMENT_QUESTION_COMMENT';
export const DELETE_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED: any =
  'DELETE_ASSESSMENT_QUESTION_COMMENT_SUCCEEDED';
export const DELETE_ASSESSMENT_QUESTION_COMMENT_FAILED: any =
  'DELETE_ASSESSMENT_QUESTION_COMMENT_FAILED';

// Answer comment Assessment
export const GET_ASSESSMENT_ANSWER_COMMENT: any = 'GET_ASSESSMENT_ANSWER_COMMENT';
export const GET_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED: any =
  'GET_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED';
export const GET_ASSESSMENT_ANSWER_COMMENT_FAILED: any =
  'GET_ASSESSMENT_ANSWER_COMMENT_FAILED';

export const GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE: any =
  'GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE';
export const GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_SUCCEEDED: any =
  'GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_SUCCEEDED';
export const GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_FAILED: any =
  'GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE_FAILED';

export const ADD_ASSESSMENT_ANSWER_COMMENT: any = 'ADD_ASSESSMENT_ANSWER_COMMENT';
export const ADD_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED: any =
  'ADD_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED';
export const ADD_ASSESSMENT_ANSWER_COMMENT_FAILED: any =
  'ADD_ASSESSMENT_ANSWER_COMMENT_FAILED';

export const EDIT_ASSESSMENT_ANSWER_COMMENT: any = 'EDIT_ASSESSMENT_ANSWER_COMMENT';
export const EDIT_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED: any =
  'EDIT_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED';
export const EDIT_ASSESSMENT_ANSWER_COMMENT_FAILED: any =
  'EDIT_ASSESSMENT_ANSWER_COMMENT_FAILED';

export const DELETE_ASSESSMENT_ANSWER_COMMENT: any =
  'DELETE_ASSESSMENT_ANSWER_COMMENT';
export const DELETE_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED: any =
  'DELETE_ASSESSMENT_ANSWER_COMMENT_SUCCEEDED';
export const DELETE_ASSESSMENT_ANSWER_COMMENT_FAILED: any =
  'DELETE_ASSESSMENT_ANSWER_COMMENT_FAILED';

//feedback
export const GET_ASSESSMENT_FEEDBACK: any = 'GET_ASSESSMENT_FEEDBACK';
export const GET_ASSESSMENT_FEEDBACK_SUCCEEDED: any =
  'GET_ASSESSMENT_FEEDBACK_SUCCEEDED';
export const GET_ASSESSMENT_FEEDBACK_FAILED: any = 'GET_ASSESSMENT_FEEDBACK_FAILED';

export const GET_ASSESSMENT_FEEDBACK_BY_ID: any = 'GET_ASSESSMENT_FEEDBACK_BY_ID';
export const GET_ASSESSMENT_FEEDBACK_BY_ID_SUCCEEDED: any =
  'GET_ASSESSMENT_FEEDBACK_BY_ID_SUCCEEDED';
export const GET_ASSESSMENT_FEEDBACK_BY_ID_FAILED: any =
  'GET_ASSESSMENT_FEEDBACK_BY_ID_FAILED';

export const ADD_ASSESSMENT_FEEDBACK: any = 'ADD_ASSESSMENT_FEEDBACK';
export const ADD_ASSESSMENT_FEEDBACK_SUCCEEDED: any =
  'ADD_ASSESSMENT_FEEDBACK_SUCCEEDED';
export const ADD_ASSESSMENT_FEEDBACK_FAILED: any = 'ADD_ASSESSMENT_FEEDBACK_FAILED';

export const EDIT_ASSESSMENT_FEEDBACK: any = 'EDIT_ASSESSMENT_FEEDBACK';
export const EDIT_ASSESSMENT_FEEDBACK_SUCCEEDED: any =
  'EDIT_ASSESSMENT_FEEDBACK_SUCCEEDED';
export const EDIT_ASSESSMENT_FEEDBACK_FAILED: any =
  'EDIT_ASSESSMENT_FEEDBACK_FAILED';

export const DELETE_ASSESSMENT_FEEDBACK: any = 'DELETE_ASSESSMENT_FEEDBACK';
export const DELETE_ASSESSMENT_FEEDBACK_SUCCEEDED: any =
  'DELETE_ASSESSMENT_FEEDBACK_SUCCEEDED';
export const DELETE_ASSESSMENT_FEEDBACK_FAILED: any =
  'DELETE_ASSESSMENT_FEEDBACK_FAILED';

// Extra

export const GET_WEBSITE_INFO: any = 'GET_WEBSITE_INFO';
export const GET_WEBSITE_INFO_SUCCEEDED: any = 'GET_WEBSITE_INFO_SUCCEEDED';
export const GET_WEBSITE_INFO_FAILED: any = 'GET_WEBSITE_INFO_FAILED';

export const CLEAR_HELPER_STATE: any = 'CLEAR_HELPER_STATE';

export const GET_HOME_SEARCH: any = 'GET_HOME_SEARCH';
export const GET_HOME_SEARCH_SUCCESS: any = 'GET_HOME_SEARCH_SUCCESS';
export const GET_HOME_SEARCH_FAILURE: any = 'GET_HOME_SEARCH_FAILURE';

export const CLEAR_HOME_DROPDOWN: any = 'CLEAR_HOME_DROPDOWN';

//NOTES
export const SET_NOTES_DATA: any = 'SET_NOTES_DATA';
export const SET_NOTES_EDIT_DATA: any = 'SET_NOTES_EDIT_DATA';
export const SET_NOTES_ERROR_DATA: any = 'SET_NOTES_ERROR_DATA';
export const CLEAR_NOTES_ERROR: any = 'CLEAR_NOTES_ERROR';

//Notes Course
export const GET_NOTES_COURSE_REQUEST: any = 'GET_NOTES_COURSE_REQUEST';
export const GET_NOTES_COURSE_SUCCEEDED: any = 'GET_NOTES_COURSE_SUCCEEDED';
export const GET_NOTES_COURSE_FAILED: any = 'GET_NOTES_COURSE_FAILED';

export const GET_NOTES_COURSE_BY_SLUG_REQUEST: any =
  'GET_NOTES_COURSE_BY_SLUG_REQUEST';
export const GET_NOTES_COURSE_BY_SLUG_SUCCEEDED: any =
  'GET_NOTES_COURSE_BY_SLUG_SUCCEEDED';
export const GET_NOTES_COURSE_BY_SLUG_FAILED: any =
  'GET_NOTES_COURSE_BY_SLUG_FAILED';

export const ADD_NOTES_COURSE_REQUEST: any = 'ADD_NOTES_COURSE_REQUEST';
export const ADD_NOTES_COURSE_SUCCEEDED: any = 'ADD_NOTES_COURSE_SUCCEEDED';
export const ADD_NOTES_COURSE_FAILED: any = 'ADD_NOTES_COURSE_FAILED';

export const EDIT_NOTES_COURSE_REQUEST: any = 'EDIT_NOTES_COURSE_REQUEST';
export const EDIT_NOTES_COURSE_SUCCEEDED: any = 'EDIT_NOTES_COURSE_SUCCEEDED';
export const EDIT_NOTES_COURSE_FAILED: any = 'EDIT_NOTES_COURSE_FAILED';

export const DELETE_NOTES_COURSE_REQUEST: any = 'DELETE_NOTES_COURSE_REQUEST';
export const DELETE_NOTES_COURSE_SUCCEEDED: any = 'DELETE_NOTES_COURSE_SUCCEEDED';
export const DELETE_NOTES_COURSE_FAILED: any = 'DELETE_NOTES_COURSE_FAILED';

//Notes Subject
export const GET_NOTES_SUBJECT_REQUEST: any = 'GET_NOTES_SUBJECT_REQUEST';
export const GET_NOTES_SUBJECT_SUCCEEDED: any = 'GET_NOTES_SUBJECT_SUCCEEDED';
export const GET_NOTES_SUBJECT_FAILED: any = 'GET_NOTES_SUBJECT_FAILED';

export const GET_NOTES_SUBJECT_BY_SLUG_REQUEST: any =
  'GET_NOTES_SUBJECT_BY_SLUG_REQUEST';
export const GET_NOTES_SUBJECT_BY_SLUG_SUCCEEDED: any =
  'GET_NOTES_SUBJECT_BY_SLUG_SUCCEEDED';
export const GET_NOTES_SUBJECT_BY_SLUG_FAILED: any =
  'GET_NOTES_SUBJECT_BY_SLUG_FAILED';

export const ADD_NOTES_SUBJECT_REQUEST: any = 'ADD_NOTES_SUBJECT_REQUEST';
export const ADD_NOTES_SUBJECT_SUCCEEDED: any = 'ADD_NOTES_SUBJECT_SUCCEEDED';
export const ADD_NOTES_SUBJECT_FAILED: any = 'ADD_NOTES_SUBJECT_FAILED';

export const EDIT_NOTES_SUBJECT_REQUEST: any = 'EDIT_NOTES_SUBJECT_REQUEST';
export const EDIT_NOTES_SUBJECT_SUCCEEDED: any = 'EDIT_NOTES_SUBJECT_SUCCEEDED';
export const EDIT_NOTES_SUBJECT_FAILED: any = 'EDIT_NOTES_SUBJECT_FAILED';

export const DELETE_NOTES_SUBJECT_REQUEST: any = 'DELETE_NOTES_SUBJECT_REQUEST';
export const DELETE_NOTES_SUBJECT_SUCCEEDED: any = 'DELETE_NOTES_SUBJECT_SUCCEEDED';
export const DELETE_NOTES_SUBJECT_FAILED: any = 'DELETE_NOTES_SUBJECT_FAILED';

//Notes Chapter
export const GET_NOTES_CHAPTER_REQUEST: any = 'GET_NOTES_CHAPTER_REQUEST';
export const GET_NOTES_CHAPTER_SUCCEEDED: any = 'GET_NOTES_CHAPTER_SUCCEEDED';
export const GET_NOTES_CHAPTER_FAILED: any = 'GET_NOTES_CHAPTER_FAILED';

export const GET_NOTES_CHAPTER_BY_SLUG_REQUEST: any =
  'GET_NOTES_CHAPTER_BY_SLUG_REQUEST';
export const GET_NOTES_CHAPTER_BY_SLUG_SUCCEEDED: any =
  'GET_NOTES_CHAPTER_BY_SLUG_SUCCEEDED';
export const GET_NOTES_CHAPTER_BY_SLUG_FAILED: any =
  'GET_NOTES_CHAPTER_BY_SLUG_FAILED';

export const ADD_NOTES_CHAPTER_REQUEST: any = 'ADD_NOTES_CHAPTER_REQUEST';
export const ADD_NOTES_CHAPTER_SUCCEEDED: any = 'ADD_NOTES_CHAPTER_SUCCEEDED';
export const ADD_NOTES_CHAPTER_FAILED: any = 'ADD_NOTES_CHAPTER_FAILED';

export const EDIT_NOTES_CHAPTER_REQUEST: any = 'EDIT_NOTES_CHAPTER_REQUEST';
export const EDIT_NOTES_CHAPTER_SUCCEEDED: any = 'EDIT_NOTES_CHAPTER_SUCCEEDED';
export const EDIT_NOTES_CHAPTER_FAILED: any = 'EDIT_NOTES_CHAPTER_FAILED';

export const DELETE_NOTES_CHAPTER_REQUEST: any = 'DELETE_NOTES_CHAPTER_REQUEST';
export const DELETE_NOTES_CHAPTER_SUCCEEDED: any = 'DELETE_NOTES_CHAPTER_SUCCEEDED';
export const DELETE_NOTES_CHAPTER_FAILED: any = 'DELETE_NOTES_CHAPTER_FAILED';

//Notes Content
export const GET_NOTES_CONTENT_REQUEST: any = 'GET_NOTES_CONTENT_REQUEST';
export const GET_NOTES_CONTENT_SUCCEEDED: any = 'GET_NOTES_CONTENT_SUCCEEDED';
export const GET_NOTES_CONTENT_FAILED: any = 'GET_NOTES_CONTENT_FAILED';

export const GET_NOTES_CONTENT_BY_SLUG_REQUEST: any =
  'GET_NOTES_CONTENT_BY_SLUG_REQUEST';
export const GET_NOTES_CONTENT_BY_SLUG_SUCCEEDED: any =
  'GET_NOTES_CONTENT_BY_SLUG_SUCCEEDED';
export const GET_NOTES_CONTENT_BY_SLUG_FAILED: any =
  'GET_NOTES_CONTENT_BY_SLUG_FAILED';

export const ADD_NOTES_CONTENT_REQUEST: any = 'ADD_NOTES_CONTENT_REQUEST';
export const ADD_NOTES_CONTENT_SUCCEEDED: any = 'ADD_NOTES_CONTENT_SUCCEEDED';
export const ADD_NOTES_CONTENT_FAILED: any = 'ADD_NOTES_CONTENT_FAILED';

export const EDIT_NOTES_CONTENT_REQUEST: any = 'EDIT_NOTES_CONTENT_REQUEST';
export const EDIT_NOTES_CONTENT_SUCCEEDED: any = 'EDIT_NOTES_CONTENT_SUCCEEDED';
export const EDIT_NOTES_CONTENT_FAILED: any = 'EDIT_NOTES_CONTENT_FAILED';

export const DELETE_NOTES_CONTENT_REQUEST: any = 'DELETE_NOTES_CONTENT_REQUEST';
export const DELETE_NOTES_CONTENT_SUCCEEDED: any = 'DELETE_NOTES_CONTENT_SUCCEEDED';
export const DELETE_NOTES_CONTENT_FAILED: any = 'DELETE_NOTES_CONTENT_FAILED';

//NOTES COURSE LANDING

export const GET_LANDING_NOTES_COURSE_REQUEST: any =
  'GET_LANDING_NOTES_COURSE_REQUEST';
export const GET_LANDING_NOTES_COURSE_SUCCEEDED: any =
  'GET_LANDING_NOTES_COURSE_SUCCEEDED';
export const GET_LANDING_NOTES_COURSE_FAILED: any =
  'GET_LANDING_NOTES_COURSE_FAILED';

export const GET_LANDING_NOTES_COURSE_BY_PAGE: any =
  'GET_LANDING_NOTES_COURSE_BY_PAGE';
export const GET_LANDING_NOTES_COURSE_BY_PAGE_SUCCEEDED: any =
  'GET_LANDING_NOTES_COURSE_BY_PAGE_SUCCEEDED';
export const GET_LANDING_NOTES_COURSE_BY_PAGE_FAILED: any =
  'GET_LANDING_NOTES_COURSE_BY_PAGE_FAILED';

export const GET_LANDING_NOTES_COURSE_DETAIL_REQUEST: any =
  'GET_LANDING_NOTES_COURSE_DETAIL_REQUEST';
export const GET_LANDING_NOTES_COURSE_DETAIL_SUCCEEDED: any =
  'GET_LANDING_NOTES_COURSE_DETAIL_SUCCEEDED';
export const GET_LANDING_NOTES_COURSE_DETAIL_FAILED: any =
  'GET_LANDING_NOTES_COURSE_DETAIL_FAILED';

export const CLEAR_NOTES_COURSE_QUERY: any = 'CLEAR_NOTES_COURSE_QUERY';

//NOTES SUBJECT LANDING

export const GET_LANDING_NOTES_SUBJECT_DETAIL_REQUEST: any =
  'GET_LANDING_NOTES_SUBJECT_DETAIL_REQUEST';
export const GET_LANDING_NOTES_SUBJECT_DETAIL_SUCCEEDED: any =
  'GET_LANDING_NOTES_SUBJECT_DETAIL_SUCCEEDED';
export const GET_LANDING_NOTES_SUBJECT_DETAIL_FAILED: any =
  'GET_LANDING_NOTES_SUBJECT_DETAIL_FAILED';

//Notes Category
export const GET_NOTES_CATEGORY_REQUEST: any = 'GET_NOTES_CATEGORY_REQUEST';
export const GET_NOTES_CATEGORY_SUCCEEDED: any = 'GET_NOTES_CATEGORY_SUCCEEDED';
export const GET_NOTES_CATEGORY_FAILED: any = 'GET_NOTES_CATEGORY_FAILED';

export const ADD_NOTES_CATEGORY_REQUEST: any = 'ADD_NOTES_CATEGORY_REQUEST';
export const ADD_NOTES_CATEGORY_SUCCEEDED: any = 'ADD_NOTES_CATEGORY_SUCCEEDED';
export const ADD_NOTES_CATEGORY_FAILED: any = 'ADD_NOTES_CATEGORY_FAILED';

export const EDIT_NOTES_CATEGORY_REQUEST: any = 'EDIT_NOTES_CATEGORY_REQUEST';
export const EDIT_NOTES_CATEGORY_SUCCEEDED: any = 'EDIT_NOTES_CATEGORY_SUCCEEDED';
export const EDIT_NOTES_CATEGORY_FAILED: any = 'EDIT_NOTES_CATEGORY_FAILED';

export const DELETE_NOTES_CATEGORY_REQUEST: any = 'DELETE_NOTES_CATEGORY_REQUEST';
export const DELETE_NOTES_CATEGORY_SUCCEEDED: any =
  'DELETE_NOTES_CATEGORY_SUCCEEDED';
export const DELETE_NOTES_CATEGORY_FAILED: any = 'DELETE_NOTES_CATEGORY_FAILED';

// Live user track
export const TOGGLE_USER_LIVE: string = 'TOGGLE_USER_LIVE';
export const LIVE_USER_COUNT: string = 'LIVE_USER_COUNT';

//partner
export const GET_PARTNER_REQUEST: any = 'GET_PARTNER_REQUEST';
export const GET_PARTNER_REQUEST_SUCCEEDED: any = 'GET_PARTNER_REQUEST_SUCCEEDED';
export const GET_PARTNER_REQUEST_FAILED: any = 'GET_PARTNER_REQUEST_FAILED';
export const SET_PARTNER_DATA: any = 'SET_PARTNER_DATA';
export const SET_PARTNER_ERROR_DATA: any = 'SET_PARTNER_ERROR_DATA';
export const ADD_PARTNER_REQUEST: any = 'ADD_PARTNER_REQUEST';
export const ADD_PARTNER_SUCCEEDED: any = 'ADD_PARTNER_SUCCEEDED';
export const ADD_PARTNER_FAILED: any = 'ADD_PARTNER_FAILED';
export const EDIT_PARTNER_REQUEST: any = 'EDIT_PARTNER_REQUEST';
export const EDIT_PARTNER_SUCCEEDED: any = 'EDIT_PARTNER_SUCCEEDED';
export const EDIT_PARTNER_FAILED: any = 'EDIT_PARTNER_REQUEST';
export const GET_PARTNER_CLIENT_REQUEST: any = 'GET_PARTNER_CLIENT_REQUEST';
export const GET_PARTNER_CLIENT_SUCCEEDED: any = 'GET_PARTNER_CLIENT_SUCCEEDED';
export const GET_PARTNER_CLIENT_FAILED: any = 'GET_PARTNER_CLIENT_FAILED';
export const GET_PARTNER_INFO_REQUEST: any = 'GET_PARTNER_INFO_REQUEST';
export const GET_PARTNER_INFO_SUCCEEDED: any = 'GET_PARTNER_INFO_SUCCEEDED';
export const GET_PARTNER_INFO_FAILED: any = 'GET_PARTNER_INFO_FAILED';

//Partner's Clients payment
export const GET_PARTNER_CLIENT_PAYMENT_REQUEST: any =
  'GET_PARTNER_CLIENT_PAYMENT_REQUEST';
export const GET_PARTNER_CLIENT_PAYMENT_SUCCEEDED: any =
  'GET_PARTNER_CLIENT_PAYMENT_SUCCEEDED';
export const GET_PARTNER_CLIENT_PAYMENT_FAILED: any =
  'GET_PARTNER_CLIENT_PAYMENT_FAILED';

//MOCK TEST LEADERBOARD
export const GET_MOCKTEST_LEADERBOARD: any = 'GET_MOCKTEST_LEADERBOARD';
export const GET_MOCKTEST_LEADERBOARD_SUCCEEDED: any =
  'GET_MOCKTEST_LEADERBOARD_SUCCEEDED';
export const GET_MOCKTEST_LEADERBOARD_FAILED: any =
  'GET_MOCKTEST_LEADERBOARD_FAILED';

//CRUD of Segment
export const GET_SEGMENT_REQUEST: any = 'GET_SEGMENT_REQUEST';
export const GET_SEGMENT_SUCCEEDED: any = 'GET_SEGMENT_SUCCEEDED';
export const GET_SEGMENT_FAILED: any = 'GET_SEGMENT_REQUEST';
export const ADD_SEGMENT_REQUEST: any = 'ADD_SEGMENT_REQUEST';
export const ADD_SEGMENT_SUCCEEDED: any = 'ADD_SEGMENT_SUCCEEDED';
export const ADD_SEGMENT_FAILED: any = 'ADD_SEGMENT_FAILED';
export const EDIT_SEGMENT_REQUEST: any = 'EDIT_SEGMENT_REQUEST';
export const EDIT_SEGMENT_SUCCEEDED: any = 'EDIT_SEGMENT_SUCCEEDED';
export const EDIT_SEGMENT_FAILED: any = 'EDIT_SEGMENT_FAILED';
export const DELETE_SEGMENT_REQUEST: any = 'DELETE_SEGMENT_REQUEST';
export const DELETE_SEGMENT_SUCCEEDED: any = 'DELETE_SEGMENT_SUCCEEDED';
export const DELETE_SEGMENT_FAILED: any = 'DELETE_SEGMENT_FAILED';
export const SET_SEGMENT_DATA: any = 'SET_SEGMENT_DATA';
//PHYSICAL CLASS
export const GET_PHYSICAL_CLASS_REQUEST: any = 'GET_PHYSICAL_CLASS_REQUEST';
export const GET_PHYSICAL_CLASS_SUCCEEDED: any = 'GET_PHYSICAL_CLASS_SUCCEEDED';
export const GET_PHYSICAL_CLASS_FAILED: any = 'GET_PHYSICAL_CLASS_FAILED';
export const GET_PHYSICAL_CLASS_INFO_REQUEST: any =
  'GET_PHYSICAL_CLASS_INFO_REQUEST';
export const GET_PHYSICAL_CLASS_INFO_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_INFO_SUCCEEDED';
export const GET_PHYSICAL_CLASS_INFO_FAILED: any = 'GET_PHYSICAL_CLASS_INFO_FAILED';
export const SET_PHYSICAL_CLASS_DATA: any = 'SET_PHYSICAL_CLASS_DATA';
export const SET_PHYSICAL_CLASS_ERROR_DATA: any = 'SET_PHYSICAL_CLASS_ERROR_DATA';
export const ADD_PHYSICAL_CLASS_REQUEST: any = 'ADD_PHYSICAL_CLASS_REQUEST';
export const ADD_PHYSICAL_CLASS_SUCCEEDED: any = 'ADD_PHYSICAL_CLASS_SUCCEEDED';
export const ADD_PHYSICAL_CLASS_FAILED: any = 'ADD_PHYSICAL_CLASS_FAILED';
export const EDIT_PHYSICAL_CLASS_REQUEST: any = 'EDIT_PHYSICAL_CLASS_REQUEST';
export const EDIT_PHYSICAL_CLASS_SUCCEEDED: any = 'EDIT_PHYSICAL_CLASS_SUCCEEDED';
export const EDIT_PHYSICAL_CLASS_FAILED: any = 'EDIT_PHYSICAL_CLASS_REQUEST';
export const DELETE_PHYSICAL_CLASS_REQUEST: any = 'DELETE_PHYSICAL_CLASS_REQUEST';
export const DELETE_PHYSICAL_CLASS_SUCCEEDED: any =
  'DELETE_PHYSICAL_CLASS_SUCCEEDED';
export const DELETE_PHYSICAL_CLASS_FAILED: any = 'DELETE_PHYSICAL_CLASS_FAILED';

//Physical class student task
export const GET_PHYSICAL_CLASS_STUDENT_TASK_REQUEST: any =
  'GET_PHYSICAL_CLASS_STUDENT_TASK_REQUEST';
export const GET_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED';
export const GET_PHYSICAL_CLASS_STUDENT_TASK_FAILED: any =
  'GET_PHYSICAL_CLASS_STUDENT_TASK_FAILED';

export const ADD_PHYSICAL_CLASS_STUDENT_TASK_REQUEST: any =
  'ADD_PHYSICAL_CLASS_STUDENT_TASK_REQUEST';
export const ADD_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED: any =
  'ADD_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED';
export const ADD_PHYSICAL_CLASS_STUDENT_TASK_FAILED: any =
  'ADD_PHYSICAL_CLASS_STUDENT_TASK_FAILED';

export const EDIT_PHYSICAL_CLASS_STUDENT_TASK_REQUEST: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_TASK_REQUEST';
export const EDIT_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED';
export const EDIT_PHYSICAL_CLASS_STUDENT_TASK_FAILED: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_TASK_FAILED';

export const DELETE_PHYSICAL_CLASS_STUDENT_TASK_REQUEST: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_TASK_REQUEST';
export const DELETE_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_TASK_SUCCEEDED';
export const DELETE_PHYSICAL_CLASS_STUDENT_TASK_FAILED: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_TASK_FAILED';

//PHYSICAL CLASS STUDENT
export const GET_PHYSICAL_CLASS_STUDENT_REQUEST: any =
  'GET_PHYSICAL_CLASS_STUDENT_REQUEST';
export const GET_PHYSICAL_CLASS_STUDENT_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_STUDENT_SUCCEEDED';
export const GET_PHYSICAL_CLASS_STUDENT_FAILED: any =
  'GET_PHYSICAL_CLASS_STUDENT_FAILED';

export const ADD_PHYSICAL_CLASS_STUDENT_REQUEST: any =
  'ADD_PHYSICAL_CLASS_STUDENT_REQUEST';
export const ADD_PHYSICAL_CLASS_STUDENT_SUCCEEDED: any =
  'ADD_PHYSICAL_CLASS_STUDENT_SUCCEEDED';
export const ADD_PHYSICAL_CLASS_STUDENT_FAILED: any =
  'ADD_PHYSICAL_CLASS_STUDENT_FAILED';
export const EDIT_PHYSICAL_CLASS_STUDENT_REQUEST: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_REQUEST';
export const EDIT_PHYSICAL_CLASS_STUDENT_SUCCEEDED: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_SUCCEEDED';
export const EDIT_PHYSICAL_CLASS_STUDENT_FAILED: any =
  'EDIT_PHYSICAL_CLASS_STUDENT_FAILED';
export const DELETE_PHYSICAL_CLASS_STUDENT_REQUEST: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_REQUEST';
export const DELETE_PHYSICAL_CLASS_STUDENT_SUCCEEDED: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_SUCCEEDED';
export const DELETE_PHYSICAL_CLASS_STUDENT_FAILED: any =
  'DELETE_PHYSICAL_CLASS_STUDENT_FAILED';

export const GET_PHYSICAL_CLASS_BATCHES_REQUEST: any =
  'GET_PHYSICAL_CLASS_BATCHES_REQUEST';
export const GET_PHYSICAL_CLASS_BATCHES_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_BATCHES_SUCCEEDED';
export const GET_PHYSICAL_CLASS_BATCHES_FAILED: any =
  'GET_PHYSICAL_CLASS_BATCHES_FAILED';

export const Add_PHYSICAL_CLASS_BATCHES_REQUEST: any =
  'Add_PHYSICAL_CLASS_BATCHES_REQUEST';
export const Add_PHYSICAL_CLASS_BATCHES_SUCCEEDED: any =
  'Add_PHYSICAL_CLASS_BATCHES_SUCCEEDED';
export const Add_PHYSICAL_CLASS_BATCHES_FAILED: any =
  'Add_PHYSICAL_CLASS_BATCHES_FAILED';

export const Edit_PHYSICAL_CLASS_BATCHES_REQUEST: any =
  'Edit_PHYSICAL_CLASS_BATCHES_REQUEST';
export const Edit_PHYSICAL_CLASS_BATCHES_SUCCEEDED: any =
  'Edit_PHYSICAL_CLASS_BATCHES_SUCCEEDED';
export const Edit_PHYSICAL_CLASS_BATCHES_FAILED: any =
  'Edit_PHYSICAL_CLASS_BATCHES_FAILED';

export const Delete_PHYSICAL_CLASS_BATCHES_REQUEST: any =
  'Delete_PHYSICAL_CLASS_BATCHES_REQUEST';
export const Delete_PHYSICAL_CLASS_BATCHES_SUCCEEDED: any =
  'Delete_PHYSICAL_CLASS_BATCHES_SUCCEEDED';
export const Delete_PHYSICAL_CLASS_BATCHES_FAILED: any =
  'Delete_PHYSICAL_CLASS_BATCHES_FAILED';

export const GET_PHYSICAL_CLASS_ATTENDANCE_REQUEST: any =
  'GET_PHYSICAL_CLASS_ATTENDANCE_REQUEST';
export const GET_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED';
export const GET_PHYSICAL_CLASS_ATTENDANCE_FAILED: any =
  'GET_PHYSICAL_CLASS_ATTENDANCE_FAILED';
export const ADD_PHYSICAL_CLASS_ATTENDANCE_REQUEST: any =
  'ADD_PHYSICAL_CLASS_ATTENDANCE_REQUEST';
export const ADD_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED: any =
  'ADD_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED';
export const ADD_PHYSICAL_CLASS_ATTENDANCE_FAILED: any =
  'ADD_PHYSICAL_CLASS_ATTENDANCE_FAILED';
export const EDIT_PHYSICAL_CLASS_ATTENDANCE_REQUEST: any =
  'EDIT_PHYSICAL_CLASS_ATTENDANCE_REQUEST';
export const EDIT_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED: any =
  'EDIT_PHYSICAL_CLASS_ATTENDANCE_SUCCEEDED';
export const EDIT_PHYSICAL_CLASS_ATTENDANCE_FAILED: any =
  'EDIT_PHYSICAL_CLASS_ATTENDANCE_FAILED';

export const GET_PHYSICAL_CLASS_STUDENT_LIST_REQUEST: any =
  'GET_PHYSICAL_CLASS_STUDENT_LIST_REQUEST';
export const GET_PHYSICAL_CLASS_STUDENT_LIST_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_STUDENT_LIST_SUCCEEDED';
export const GET_PHYSICAL_CLASS_STUDENT_LIST_FAILED: any =
  'GET_PHYSICAL_CLASS_STUDENT_LIST_FAILED';

// Physical class lists (Landing)
export const GET_PHYSICAL_CLASS_LIST_REQUEST: any =
  'GET_PHYSICAL_CLASS_LIST_REQUEST';
export const GET_PHYSICAL_CLASS_LIST_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_LIST_SUCCEEDED';
export const GET_PHYSICAL_CLASS_LIST_FAILED: any = 'GET_PHYSICAL_CLASS_LIST_FAILED';

export const GET_PHYSICAL_CLASS_LIST_DETAIL_REQUEST: any =
  'GET_PHYSICAL_CLASS_LIST_DETAIL_REQUEST';
export const GET_PHYSICAL_CLASS_LIST_DETAIL_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_LIST_DETAIL_SUCCEEDED';
export const GET_PHYSICAL_CLASS_LIST_DETAIL_FAILED: any =
  'GET_PHYSICAL_CLASS_LIST_DETAIL_FAILED';

export const POST_PHYSICAL_CLASS_BOOK_REQUEST: any =
  'POST_PHYSICAL_CLASS_BOOK_REQUEST';
export const POST_PHYSICAL_CLASS_BOOK_SUCCEEDED: any =
  'POST_PHYSICAL_CLASS_BOOK_SUCCEEDED';
export const POST_PHYSICAL_CLASS_BOOK_FAILED: any =
  'POST_PHYSICAL_CLASS_BOOK_FAILED';

//Physical class booking request
export const GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST: any =
  'GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST';
export const GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED: any =
  'GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED';
export const GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED: any =
  'GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED';
export const UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST: any =
  'UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST';
export const UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED: any =
  'UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_SUCCEEDED';
export const UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED: any =
  'GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_FAILED';

// Chat

export const GET_ALL_THREADS: any = 'GET_ALL_THREADS';
export const GET_ALL_THREADS_SUCCEEDED: any = 'GET_ALL_THREADS_SUCCEEDED';
export const GET_ALL_THREADS_FAILED: any = 'GET_ALL_THREADS_FAILED';

export const GET_ALL_THREAD_CHATS: any = 'GET_ALL_THREAD_CHATS';
export const GET_ALL_THREAD_CHATS_SUCCEEDED: any = 'GET_ALL_THREAD_CHATS_SUCCEEDED';
export const GET_ALL_THREAD_CHATS_FAILED: any = 'GET_ALL_THREAD_CHATS_FAILED';
export const ADD_DATA_TO_CHAT: any = 'ADD_DATA_TO_CHAT';
export const ADD_DATA_THREAD: any = 'ADD_DATA_THREAD';
export const UPDATE_THREAD: any = 'UPDATE_THREAD';
export const CHAT_STATE_MODIFY: any = 'CHAT_STATE_MODIFY';
export const UPDATE_TYPING_STATUS: any = 'UPDATE_TYPING_STATUS';
export const READ_ALL_SEEN_MESSAGES: any = 'READ_ALL_SEEN_MESSAGES';
export const MODIFY_CHAT: any = 'MODIFY_CHAT';

// Ledger
export const GET_LEDGER: any = 'GET_LEDGER';
export const GET_LEDGER_SUCCEEDED: any = 'GET_LEDGER_SUCCEEDED';
export const GET_LEDGER_FAILED: any = 'GET_LEDGER_FAILED';

export const EDIT_LEDGER_ORDER: any = 'EDIT_LEDGER_ORDER';
export const EDIT_LEDGER_ORDER_SUCCEEDED: any = 'EDIT_LEDGER_ORDER_SUCCEEDED';
export const EDIT_LEDGER_ORDER_FAILED: any = 'EDIT_LEDGER_ORDER_FAILED';

//All tasks
export const GET_TASK_REQUEST: any = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCEEDED: any = 'GET_TASK_SUCCEEDED';
export const GET_TASK_FAILED: any = 'GET_TASK_FAILED';

// Task by id
export const GET_TASK_BY_ID_REQUEST: any = 'GET_TASK_BY_ID_REQUEST';
export const GET_TASK_BY_ID_SUCCEEDED: any = 'GET_TASK_BY_ID_SUCCEEDED';
export const GET_TASK_BY_ID_FAILED: any = 'GET_TASK_BY_ID_FAILED';

export const ADD_TASK_REQUEST: any = 'ADD_TASK_REQUEST';
export const ADD_TASK_SUCCEEDED: any = 'ADD_TASK_SUCCEEDED';
export const ADD_TASK_FAILED: any = 'ADD_TASK_FAILED';

export const EDIT_TASK_REQUEST: any = 'EDIT_TASK_REQUEST';
export const EDIT_TASK_SUCCEEDED: any = 'EDIT_TASK_SUCCEEDED';
export const EDIT_TASK_FAILED: any = 'EDIT_TASK_FAILED';

export const DELETE_TASK_REQUEST: any = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCEEDED: any = 'DELETE_TASK_SUCCEEDED';
export const DELETE_TASK_FAILED: any = 'DELETE_TASK_FAILED';

export const SET_TASK_DATA: any = 'SET_TASK_DATA';

//Task contact us detail
export const GET_CONTACT_US_USER_DETAIL: any = 'GET_CONTACT_US_USER_DETAIL';
export const GET_CONTACT_US_USER_DETAIL_SUCCEEDED: any =
  'GET_CONTACT_US_USER_DETAIL_SUCCEEDED';
export const GET_CONTACT_US_USER_DETAIL_FAILED: any =
  'GET_CONTACT_US_USER_DETAIL_FAILED';

//Tasks comments
export const GET_TASK_COMMENTS_REQUEST: any = 'GET_TASK_COMMENTS_REQUEST';
export const GET_TASK_COMMENTS_SUCCEEDED: any = 'GET_TASK_COMMENTS_SUCCEEDED';
export const GET_TASK_COMMENTS_FAILED: any = 'GET_TASK_COMMENTS_FAILED';

export const ADD_TASK_COMMENTS_REQUEST: any = 'ADD_TASK_COMMENTS_REQUEST';
export const ADD_TASK_COMMENTS_SUCCEEDED: any = 'ADD_TASK_COMMENTS_SUCCEEDED';
export const ADD_TASK_COMMENTS_FAILED: any = 'ADD_TASK_COMMENTS_FAILED';

export const EDIT_TASK_COMMENTS_REQUEST: any = 'EDIT_TASK_COMMENTS_REQUEST';
export const EDIT_TASK_COMMENTS_SUCCEEDED: any = 'EDIT_TASK_COMMENTS_SUCCEEDED';
export const EDIT_TASK_COMMENTS_FAILED: any = 'EDIT_TASK_COMMENTS_FAILED';

export const DELETE_TASK_COMMENTS_REQUEST: any = 'DELETE_TASK_COMMENTS_REQUEST';
export const DELETE_TASK_COMMENTS_SUCCEEDED: any = 'DELETE_TASK_COMMENTS_SUCCEEDED';
export const DELETE_TASK_COMMENTS_FAILED: any = 'DELETE_TASK_COMMENTS_FAILED';

// All Assessment
export const GET_ALL_ASSESSMENT_REQUEST: any = 'GET_ALL_ASSESSMENT_REQUEST';
export const GET_ALL_ASSESSMENT_SUCCEEDED: any = 'GET_ALL_ASSESSMENT_SUCCEEDED';
export const GET_ALL_ASSESSMENT_FAILED: any = 'GET_ALL_ASSESSMENT_FAILED';

// Assign multiple mock test
export const ASSIGN_MULTIPLE_MOCK_TEST_REQUEST: any =
  'ASSIGN_MULTIPLE_MOCK_TEST_REQUEST';
export const ASSIGN_MULTIPLE_MOCK_TEST_SUCCEEDED: any =
  'ASSIGN_MULTIPLE_MOCK_TEST_SUCCEEDED';
export const ASSIGN_MULTIPLE_MOCK_TEST_FAILED: any =
  'ASSIGN_MULTIPLE_MOCK_TEST_FAILED';
export const CLEAR_MOCK_TEST_ASSIGN_ERROR: any = 'CLEAR_MOCK_TEST_ASSIGN_ERROR';

//college & college ambassador

//for landing
export const BECOME_COLLEGE_AMBASSADOR_REQUEST: any =
  'BECOME_COLLEGE_AMBASSADOR_REQUEST';
export const BECOME_COLLEGE_AMBASSADOR_SUCCEEDED: any =
  'BECOME_COLLEGE_AMBASSADOR_SUCCEEDED';
export const BECOME_COLLEGE_AMBASSADOR_FAILED: any =
  'BECOME_COLLEGE_AMBASSADOR_FAILED';

export const GET_LANDING_NEPAL_COLLEGE_DROPDOWN_REQUEST: any =
  'GET_LANDING_NEPAL_COLLEGE_DROPDOWN_REQUEST';
export const GET_LANDING_NEPAL_COLLEGE_DROPDOWN_SUCCEEDED: any =
  'GET_LANDING_NEPAL_COLLEGE_DROPDOWN_SUCCEEDED';
export const GET_LANDING_NEPAL_COLLEGE_DROPDOWN_FAILED: any =
  'GET_LANDING_NEPAL_COLLEGE_DROPDOWN_FAILED';

//for dashboard
export const GET_COLLEGE_AMBASSADOR_FORM_LIST_REQUEST: any =
  'GET_COLLEGE_AMBASSADOR_FORM_LIST_REQUEST';
export const GET_COLLEGE_AMBASSADOR_FORM_LIST_SUCCEEDED: any =
  'GET_COLLEGE_AMBASSADOR_FORM_LIST_SUCCEEDED';
export const GET_COLLEGE_AMBASSADOR_FORM_LIST_FAILED: any =
  'GET_COLLEGE_AMBASSADOR_FORM_LIST_FAILED';

export const GET_COLLEGE_AMBASSADOR_FORM_BY_ID_REQUEST: any =
  'GET_COLLEGE_AMBASSADOR_FORM_BY_ID_REQUEST';
export const GET_COLLEGE_AMBASSADOR_FORM_BY_ID_SUCCEEDED: any =
  'GET_COLLEGE_AMBASSADOR_FORM_BY_ID_SUCCEEDED';
export const GET_COLLEGE_AMBASSADOR_FORM_BY_ID_FAILED: any =
  'GET_COLLEGE_AMBASSADOR_FORM_BY_ID_FAILED';

export const EDIT_COLLEGE_AMBASSADOR_FORM_REQUEST: any =
  'EDIT_COLLEGE_AMBASSADOR_FORM_REQUEST';
export const EDIT_COLLEGE_AMBASSADOR_FORM_SUCCEEDED: any =
  'EDIT_COLLEGE_AMBASSADOR_FORM_SUCCEEDED';
export const EDIT_COLLEGE_AMBASSADOR_FORM_FAILED: any =
  'EDIT_COLLEGE_AMBASSADOR_FORM_FAILED';

export const GET_COLLEGE_LIST_REQUEST: any = 'GET_COLLEGE_LIST_REQUEST';
export const GET_COLLEGE_LIST_SUCCEEDED: any = 'GET_COLLEGE_LIST_SUCCEEDED';
export const GET_COLLEGE_LIST_FAILED: any = 'GET_COLLEGE_LIST_FAILED';

export const ADD_COLLEGE_REQUEST: any = 'ADD_COLLEGE_REQUEST';
export const ADD_COLLEGE_SUCCEEDED: any = 'ADD_COLLEGE_SUCCEEDED';
export const ADD_COLLEGE_FAILED: any = 'ADD_COLLEGE_FAILED';

export const EDIT_COLLEGE_REQUEST: any = 'EDIT_COLLEGE_REQUEST';
export const EDIT_COLLEGE_SUCCEEDED: any = 'EDIT_COLLEGE_SUCCEEDED';
export const EDIT_COLLEGE_FAILED: any = 'EDIT_COLLEGE_FAILED';

export const DELETE_COLLEGE_REQUEST: any = 'DELETE_COLLEGE_REQUEST';
export const DELETE_COLLEGE_SUCCEEDED: any = 'DELETE_COLLEGE_SUCCEEDED';
export const DELETE_COLLEGE_FAILED: any = 'DELETE_COLLEGE_FAILED';

export const SET_COLLEGE_DATA: any = 'SET_COLLEGE_DATA';
export const SET_COLLEGE_EDIT_DATA: any = 'SET_COLLEGE_EDIT_DATA';
