import { Avatar } from 'antd';

const AvatarComponent = ({
  avatar = '',
  firstName = null,
  lastName = null,
  size = 'large' as any,
  className = null,
  shape = null,
  biggerAvatar = false,
}) => {
  return (
    <>
      {avatar ? (
        <Avatar
          size={size as any}
          src={avatar}
          className={className}
          shape={shape}
        />
      ) : (
        <Avatar
          size={biggerAvatar ? (size as any) : 'medium'}
          className={className}
          style={{ backgroundColor: '#283360' }}
          shape={shape}
        >
          {firstName?.charAt(0)?.toUpperCase()}
          {lastName?.charAt(0)?.toUpperCase()}
        </Avatar>
      )}
    </>
  );
};

export default AvatarComponent;
