import useWidth from '@/helpers/widthUtil';
import Link from 'next/link';
import { memo } from 'react';
import CustomAntCarousel, { NextArrow, PrevArrow } from '../CustomAntCarousel';
import { MyCourseCard } from '../landing/account/myCourse';
import CourseSingleItem from '../landing/course/CourseSingleItem';
import LandingLearnings from '../LandingLearnings';

const CustomCourseCarousel = ({
  courses = [],
  myCourse = false,
  relatedCourses = false,
  myLearnings = false,
  count = typeof window !== 'undefined' && window?.innerWidth <= 1302 ? 3 : 4,
  isDetailPage = false,
  isLandingCourseSection = false,
}) => {
  const { isMobile, isTablet } = useWidth();

  const settings = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: isMobile
      ? isDetailPage
        ? 2
        : 1
      : courses?.length >= count
      ? isTablet
        ? 2
        : relatedCourses
        ? count
        : count
      : 1,
    slidesToScroll: isMobile
      ? isDetailPage
        ? 2
        : 1
      : courses?.length >= count
      ? isTablet
        ? 2
        : relatedCourses
        ? count
        : 1
      : 1,
    autoplay:
      relatedCourses || isDetailPage || isLandingCourseSection ? true : false,
    arrows: !isLandingCourseSection && (isTablet || isMobile) ? false : true,
    dots: isTablet || isMobile ? true : false,
  };

  return (
    <CustomAntCarousel
      customSettings={settings}
      relatedCourses={relatedCourses}
      isDetailPage={isDetailPage}
    >
      {courses.map((course, idx) => (
        <div key={course.id + new Date().getMilliseconds() + Math.random()}>
          <Link
            key={course.id}
            href={`${
              myLearnings
                ? `/course/lesson/${course?.course__slug}`
                : `/course/${course.slug}`
            } `}
          >
            {myLearnings ? (
              <LandingLearnings key={idx} each={course} />
            ) : myCourse ? (
              <MyCourseCard key={idx} course={course} />
            ) : (
              <CourseSingleItem key={idx} course={course} />
            )}
          </Link>
        </div>
      ))}
    </CustomAntCarousel>
  );
};

export default memo(CustomCourseCarousel);
